(function(){

    function removeUndefined(obj) {
      if (typeof obj === 'object' && obj !== null) {
          for (let key in obj) {
              if (obj.hasOwnProperty(key)) {
                  obj[key] = removeUndefined(obj[key]);
              }
          }
      }
      return obj === undefined ? null : obj;
   }


    function AtomicFiView (def,funnel) {
        AtomicFiView.SUPERconstructor.call(this,def,funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/atomic-fi"), AtomicFiView);

    AtomicFiView.prototype.wasRedirected = function () {
        return this.redirected;
    };

    AtomicFiView.prototype.startAction = function(selectedProduct, token) {
      var self = this;
      var confirmAction = new jsedn.Map();
      confirmAction.set(new jsedn.kw(":funnel-step-s12n-action/type"),
			new jsedn.kw(":funnel-step-s12n-action-type/confirm"));
      Atomic.transact({
        config: {
	  publicToken: token,
	  tasks: [{ product: selectedProduct }]
        },
        onInteraction: interaction => {
          console.log('Interaction event:', interaction.name, interaction.value)
        },
        onFinish: data => {
          console.log('Finish event:', data.taskId, data.handoff);
          self.resultData = removeUndefined(data);
          self.funnel.onAction(confirmAction,function(status){});
        },
        onClose: data => {
          console.log('Close event:', data);
          self.resultData = removeUndefined(data);
          self.funnel.onAction(confirmAction,function(status){
          });
        }
      });
    }

    AtomicFiView.prototype.createContent = function (definition) {
      var self = this;
      this.error = false;
      var error = definition[":atomic-error"]
      if (error) {
        this.error = true;
        var body = document.createElement("div");
        var message_display_div = document.createElement("div");
        message_display_div.className = "message-display";

        var title_node = document.createElement("h1");
        title_node.className = "prisma-title-component";
        title_node.appendChild(document.createTextNode("Error"));

        var text_node = document.createElement("div");
        text_node.innerHTML = definition[":atomic-error"]
        text_node.className = "prisma-text-component";
        message_display_div.appendChild(title_node);
        message_display_div.appendChild(text_node);

        body.appendChild(message_display_div);
        return body;
      }

      var body = document.createElement("div");
      var actionType = definition[":action-type"];
      var accessToken = definition[":access-token"];
      var baseUrl = self.funnel && self.funnel.handler && self.funnel.handler.baseUrl() || "";
      //add script reference
      var s = document.createElement("script");
      s.type = 'text/javascript';
      s.src = baseUrl + "/sdk/javascript/atomic-fi/index.js";
      s.onload = function() {
        self.removeActions();
        var selectedProduct = Product.DEPOSIT;
        switch (actionType) {
         case ":deposit":
	      selectedProduct = Product.DEPOSIT;
	      break;
        }
        self.startAction(selectedProduct, accessToken);
      };
      document.body.appendChild(s);
      return body;
    };

   AtomicFiView.prototype.retrieveData = function (cb){
        var r = new jsedn.Map();
        if (this.error) {
          cb(true, r);
          return;
        }
        r.set(jsedn.kw(":transact-result"), this.resultData)
        cb(true, r);
    }

    prisma_plugins = true;
})();
