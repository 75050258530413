(function() {

  function dropzoneControl (context, opts, childs) {
    dropzoneControl.SUPERconstructor.call(this,context, opts, childs);
  }

  prisma.inherit(dropzoneControl, prisma.widget);

  dropzoneControl.prototype.str2ab = function (str) {
    var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);
    for (var i=0, strLen=str.length; i<strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  dropzoneControl.prototype.createDropzone = function() {
    var self = this;
    self.uploadedFiles = {}
    var maxFiles = this.cardinality == ":one" ? 1: (isNaN(parseInt(this.maxFiles)) ? 10 : parseInt(this.maxFiles, 10));
    var maxFileSize = isNaN(parseInt(this.maxFileSize)) ? 1000 : parseInt(this.maxFileSize, 10);
    var fileTypes = {":images" : "image/*",
                     ":documents" : "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                     ":zipped" : "application/x-rar-compressed,application/zip,application/x-zip-compressed, multipart/x-zip"};
    var acceptedFiles = null;
    for (var ft  in fileTypes) {
      if (fileTypes.hasOwnProperty(ft) && this["accepted-files"] && this["accepted-files"].indexOf(ft) >= 0) {
        var mimetype = fileTypes[ft];
        if (acceptedFiles) {
          acceptedFiles += "," + mimetype;
        }
	else {
          acceptedFiles = mimetype;
        }
      }
    }
    self.dropzoneCtl = new Dropzone("div#" + self.dropzoneId,
				  { url: self.context.api.getUrl() + "sdk/upload",
				    addRemoveLinks: true,
				    acceptedFiles : acceptedFiles,
				    maxFiles: maxFiles,
				    maxFilesize: (maxFileSize / 1000),
				    dictDefaultMessage: self._T(":funnel-navigation",":file-upload-drop-files"),
				    dictFallbackMessage: self._T(":funnel-navigation", ":file-upload-unsupported-browser"),
				    dictFileTooBig: self._T(":funnel-navigation", ":file-upload-file-too-big"),
				    dictInvalidFileType: self._T(":funnel-navigation", ":file-upload-invalid-type"),
				    dictRemoveFile: self._T(":funnel-navigation", ":file-upload-remove"),
				    dictRemoveFileConfirmation: self._T(":funnel-navigation", ":file-upload-remove-confirm"),
				    dictMaxFilesExceeded: self._T(":funnel-navigation", ":file-upload-number-exceeded"),
				    init: function() {
                                      this.on("addedfile", function(file) { self.onAddedFile(file);});
                                      this.on("removedfile", function(file) { self.onRemovedFile(file);});
                                      this.on("success", function(file, response) { self.onUploaded(file, response);});
                                      this.on("error", function(file, errorMessage) { self.onError(file, errorMessage);});
				    }});
    var currentFiles = this.value;
    var processFile = function(idx) {
      if (idx >= currentFiles.length) {
        return;
      }
      self.downloadFile(currentFiles[idx], function(data) {
        if (data) {
          self.resolveFile(currentFiles[idx],
			   function(fileData, ednData) {
                                 if (!fileData) return;
                                 var blob = new Blob([self.str2ab(data)], {type: fileData.fileType});
                                 var file = new File([blob], fileData.fileName, {type: fileData.fileType});
                                 file.status = Dropzone.SUCCESS;
                                 file.accepted = true;
                                 self.dropzoneCtl.files.push(file);
                                 self.dropzoneCtl.emit("addedfile", file);
                                 self.dropzoneCtl._enqueueThumbnail(file);
                                 self.uploadedFiles[fileData.fileName] = ednData;
                           });
        }
        processFile(idx+1);
      });
    }
    if (currentFiles) processFile(0);
  }

  dropzoneControl.prototype.downloadFile = function(fileReference, cb) {
    this.context.api.get(this.context.api.getUrl() + "sdk/download/" + fileReference, [],
                         function(response) {
                           cb(response)
                         },
                         function(error) {
                           cb();
                         });
  }

  dropzoneControl.prototype.resolveFile = function(fileReference, cb) {
     var self = this;
     if (!fileReference) return;
     self.context.api.get(self.context.api.getUrl() + "sdk/file/" + fileReference, [],
                              function(response) {
                                  var response = jsedn.parse(response);
                                  var f = {fileReference : response.at(jsedn.kw(":id")),
                                           fileName : response.at(jsedn.kw(":filename")),
                                           fileType : response.at(jsedn.kw(":mimetype"))};
                                  cb(f, response);
                               },
                               function(error) {
                               });
    }

  dropzoneControl.prototype.createDropzoneNode = function(parentNode) {
    var self = this;
    var dropzoneNode = document.createElement("div");
    self.dropzoneId = self.id + "_dropzone";
    dropzoneNode.setAttribute("id", self.dropzoneId);

    dropzoneNode.className = "dropzone";

    var dcss = document.createElement("link");
    dcss.setAttribute("href",this.context.api.getUrl()+"/css/dropzone.css");
    dcss.setAttribute("type","text/css");
    dcss.setAttribute("rel","stylesheet");
    var body = document.getElementsByTagName("body");
    var scriptNode = body.lenght > 0 ? body[0] : parentNode;
    scriptNode.appendChild(dcss);

    var script = document.createElement("script");
    script.setAttribute("src",this.context.api.getUrl()+"/scripts/dropzone.min.js");
    script.setAttribute("type","application/javascript");

    scriptNode.appendChild(script);
    var it = setInterval(function(){
      if(window.Dropzone){
        clearInterval(it);
        self.createDropzone();
      }
    },50);

    return dropzoneNode;
  }

  prisma.dropzoneControl = dropzoneControl;

  function file_uploader (context, opts, childs) {
    file_uploader.SUPERconstructor.call(this,context, opts, childs);
  }

  prisma.inherit(file_uploader, dropzoneControl);

  file_uploader.prototype.create = function (){
    var width = "auto";
    var height = "auto";
    if (this.size && this.size.width)
        width = this.size.width;
    if (this.size && this.size.height)
        height = this.size.height;

    var s = "width:" + width + ";" +
            "height:" + height + ";" +
            (height != "auto" ? "min-height:" + height : "") +
            (this.border ? "border: " + (this.border.width || "1px") + " solid " + (this.border.color || "black") + ";":"" )+
            (this.padding ? "padding: " + this.padding + ";":"") +
            (this.background ? "background: " + this.background + ";":"");

    var self = this;
    var fileUploaderWrapper = document.createElement("div");
    fileUploaderWrapper.className = "prisma-dropzone-component";
    fileUploaderWrapper.id = this.id;

    var fileUploader = document.createElement("div");
    fileUploader.className = "form-group";

    var dropzoneNode = self.createDropzoneNode(fileUploader);
    dropzoneNode.setAttribute("style",s);
    fileUploader.appendChild(dropzoneNode);
    fileUploaderWrapper.appendChild(fileUploader);


    self.component = fileUploaderWrapper;
    this.domNode = fileUploaderWrapper;
    return fileUploaderWrapper;
  }

  file_uploader.prototype.showRequired = function () {
    prisma.addClass(this.component, "has-error");
    prisma.addClass(this.component, "fixed");
    var requiredNode = document.createElement("div");
    requiredNode.innerHTML = "<span class='arrow'></span><p>" + this._T(":funnel-navigation",":file-upload-at-least-one") + "</p>";
    requiredNode.className = "error align-top";
    this.component.appendChild(requiredNode);
  }

  file_uploader.prototype.hideRequired = function () {
    prisma.removeClass(this.component, "has-error");
    prisma.removeClass(this.component, "fixed");
    var n = this.component.getElementsByClassName("error align-top");
    if (n.length > 0) {
      var errorNode = n[0];
      errorNode.parentElement.removeChild(errorNode);
    }
  }

  file_uploader.prototype.onUploaded = function (file, response) {
    this.uploadedFiles[file.name] = jsedn.parse(response);
  }

  file_uploader.prototype.onAddedFile = function (file) {

  }

  file_uploader.prototype.onError = function(file, errorMessage) {
    if (window.console) console.log("upload error" + errorMessage);
    this.uploadedFiles[file.name] = -1;
  }

  file_uploader.prototype.onRemovedFile = function (file) {
    this.uploadedFiles[file.name] = null;
  }

  file_uploader.prototype.hasFiles = function() {
    //no files with errors allowed
    for(var prop in this.uploadedFiles) {
      if (this.uploadedFiles.hasOwnProperty(prop) && this.uploadedFiles[prop] == -1) {
        return false;
      }
    }

    for(var prop in this.uploadedFiles) {
      if (this.uploadedFiles.hasOwnProperty(prop) && this.uploadedFiles[prop] != null) {
        return true;
      }
    }
    return false;
  }

  file_uploader.prototype.retrieveData = function (cb) {
    this.hideRequired();
    if (!this.hasFiles()) {
      if (this.required) {
          this.showRequired();
          cb(false);
      }
      else {
          cb(true, []);
      }
      return;
    }
    var uploadedFileIds = [];
    for (var f in this.uploadedFiles) {
      if (this.uploadedFiles.hasOwnProperty(f)) {
        var fileData = this.uploadedFiles[f];
        if (fileData != null && fileData != -1) {
          uploadedFileIds.push(fileData.at(jsedn.kw(":id")));
        }
      }
    }
    cb(true, uploadedFileIds);
  }

  prisma.file_uploader = file_uploader;


})();
