(function () {
    function RedirectView(definition, funnel) {
        this.redirected = false;

        RedirectView.SUPERconstructor.call(this, definition, funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/redirect"), RedirectView);

    RedirectView.prototype.wasRedirected = function () {
        return this.redirected;
    };

    RedirectView.prototype.createContent = function (definition) {
        var url = definition[":url"];
        var method = definition[":method"];
        var newWindow = definition[":new-window"];
        var includeSystemParams = definition[":include-system-params"];
        var parameters = definition[":parameters"];
        var disableEvent = definition[":disable-sdk-event"] || false;

        var onRedirect = disableEvent ? undefined : this.funnel.handler.onRedirect;
        var campaignId = this.funnel.campaignId;
        var placeholderId = this.funnel.placeholderId;
        var trackingToken = this.funnel.trackingToken;
        var trailId = this.funnel.handler.tracker.currentTrail;

        var parametersHash = {};
        if (parameters) {
            for (var parameter in parameters) {
                if (parameters.hasOwnProperty(parameter)) {
                    if (parameters[parameter]) {
                        var value = parameters[parameter][":value"];
                        var splitValue = value.split("=");
                        parametersHash[splitValue[0]] = splitValue[1];
                    }
                }
            }
        }

        var systemParamsHash = {};
        systemParamsHash["campaign-id"] = campaignId;
        systemParamsHash["placeholder-id"] = placeholderId;
        systemParamsHash["trail-id"] = trailId;
        systemParamsHash["tracking-token"] = trackingToken;

        var allParams = {};
        for (var param in parametersHash) {
          if (parametersHash.hasOwnProperty(param)) {
             allParams[param] = parametersHash[param];
          }
        }
        for (var param in systemParamsHash) {
            if (systemParamsHash.hasOwnProperty(param)) {
                allParams[param] = systemParamsHash[param];
            }
        }

        var queryString = function () {
            var result = "";

            var isFirst = true;
            var append = function (params) {
                for (var param in params) {
                    if (params.hasOwnProperty(param)) {
                        if (isFirst) {
                            isFirst = false;
                        } else {
                            result += "&";
                        }
                        result += param + "=" + params[param];
                    }
                }
            };

            append(parametersHash);
            if (includeSystemParams) {
                append(systemParamsHash);
            }

            if (result) {
                result = (url.indexOf("?") == -1 ? "?" : "&") + result;
            }

            return result;
        };

        if (onRedirect) {
            onRedirect(url + queryString(), allParams);
        } else if (method == ":post") {
            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", url);
            if (newWindow) {
                form.setAttribute("target", "_blank");
            }

            var addParams = function (params) {
                for (var param in params) {
                    if (params.hasOwnProperty(param)) {
                        var hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", param);
                        hiddenField.setAttribute("value", params[param]);
                        form.appendChild(hiddenField);
                    }
                }
            };

            addParams(parametersHash);
            if (includeSystemParams) {
               addParams(systemParamsHash);
             }

            document.body.appendChild(form);
            form.submit();
        } else {
          if (newWindow && typeof(this.funnel.sandboxHash) == "undefined") {
              setTimeout(() => {
                  prisma.openWindow(url + queryString());
              });
          } else {
              if (typeof(this.funnel.sandboxHash) != "undefined") {
                  window.location.href = url + queryString();
              } else {
                  window.top.location.href = url + queryString();
              }
          }
        }

        this.redirected = true;
        this.redirectedUrl = url;
    };

    prisma_plugins = true;
})();
