(function () {
   
    function OTPVerificationView(def, funnel) {
        OTPVerificationView.SUPERconstructor.call(this, def, funnel);
    }

    prisma.registerPlugin(jsedn.kw(":built-in/otp-verification"), OTPVerificationView,prisma.Page);


    OTPVerificationView.prototype.createContent = function (definition) {
        var self = this;
        this.definition = definition;
        var body = OTPVerificationView.SUPERclass.createContent.call(this, definition[":layout"])
        this.tkey = ":otp-verification";
        var sendCodeBtn = this.getControl("send-code");
        var verifyBtn = this.getControl("verify");
        var digit1 = this.getControl("digit1");
        var digit2 = this.getControl("digit2");
        var digit3 = this.getControl("digit3");
        var digit4 = this.getControl("digit4");
        var digit5 = this.getControl("digit5");
        var digit6 = this.getControl("digit6");

        var cancel = this.getControl("cancel");
        var cancelled_view = this.getControl("cancelled-view")
        var main_view = this.getControl("main-view")
        var msg_error = this.getControl("msg_error");
        verifyBtn.setEnabled(false);
        cancelled_view.setVisibility(false);
        var checkCodeCompletion = function () {
            msg_error.setVisibility(false);
            verifyBtn.setEnabled(digit1.getText() != "" &&
                digit2.getText() != "" &&
                digit3.getText() != "" &&
                digit4.getText() != "" &&
                digit5.getText() != "" &&
                digit6.getText() != "");
        }

        digit1.onChange(function (v) {
            checkCodeCompletion();
            if(v!=""){
                digit2.setText("");
                digit2.setFocus(false)
            }
        });
        digit2.onChange(function (v) {
            checkCodeCompletion();
            if(v!=""){
                digit3.setText("");
                digit3.setFocus(false)
            }
        });
        digit3.onChange(function (v) {
            checkCodeCompletion();
            if(v!=""){
                digit4.setText("");
                digit4.setFocus(false)
            }
        });
        digit4.onChange(function (v) {
            checkCodeCompletion();
            if(v!=""){
                digit5.setText("");
                digit5.setFocus(false)
            }
        });
        digit5.onChange(function (v) {
            checkCodeCompletion();
            if(v!=""){
                digit6.setText("");
                digit6.setFocus(false)
            }
        });
        digit6.onChange(function (v) {
            checkCodeCompletion();
            if(v!=""){
                verifyBtn.setFocus(false)
            }
        });

        cancel.onClick(function (e) {
            var r = confirm(self._T(self.tkey, ":cancel-verification-confirmation"))
            if (r == true) {
                self.setWaitState(true);
                self.funnel.restRequest(new jsedn.Map([new jsedn.kw(":action"), new jsedn.kw(":cancel-verification")]),

                    function (data) {
                        self.setWaitState(false);
                        if (data[":status"] == true) {
                            if (!self.funnel.showInModal()) {
                                self.getControl("btnclose").setVisibility(false);
                            }
                            main_view.setVisibility(false);
                            cancelled_view.setVisibility(true);
                        } else
                            alert("ERROR " + error);
                    },
                    function (error) {
                        self.setWaitState(false);
                        alert("ERROR " + error);
                    })

            }

        });
        var ith ;
        var sendButtonWait = function (tmout) {
            if (ith)
                return;
            sendCodeBtn.setEnabled(false);
            sendCodeBtn.setText(prisma.format(self._T(self.tkey, ":wait-for-re-send-code"), tmout));
            ith = setInterval(function () {
                tmout--;
                sendCodeBtn.setText(prisma.format(self._T(self.tkey, ":wait-for-re-send-code"), tmout));
                if (tmout == 0) {
                    clearInterval(ith);
                    ith=null;
                    sendCodeBtn.setText(self._T(self.tkey, ":re-send-code"));
                    sendCodeBtn.setEnabled(true);
                }
            }, 1000);
        }
        var sendCode = function () {
            msg_error.setVisibility(false);
            self.setWaitState(true);
            self.funnel.restRequest(new jsedn.Map([new jsedn.kw(":action"), new jsedn.kw(":send-code"),
                    new jsedn.kw(":timestamp"), Date.now()
                ]),
                function (data) {
                    self.setWaitState(false);
                    if (data[":status"] == ":sent") {
                        digit1.setFocus();
                        sendButtonWait(120)
                    } else {
                        msg_error.setText(self._T(self.tkey, ":send-error"));
                        msg_error.setVisibility(true);
                    }
                },
                function (error) {
                    self.setWaitState(false);
                    msg_error.setText(self._T(self.tkey, ":send-error"));
                    msg_error.setVisibility(true);
                })
        }
        msg_error.setVisibility(false);
        if (definition[":last-send-timestamp"]) {
            var tmout = parseInt(((definition[":last-send-timestamp"] + 120000) - Date.now()) / 1000);
            sendButtonWait(tmout);
        } else {
            sendButtonWait(120);
            setTimeout(function () {
                sendCode();
            }, 0);
        }

        sendCodeBtn.onClick(function (e) {
            sendCode();
        });
        return body;
    };
    OTPVerificationView.prototype.retrieveData = function (cb) {
        var r = new jsedn.Map();
        r.set(new jsedn.kw(":code"), 
            this.getControl("digit1").getText() +
            this.getControl("digit2").getText() +
            this.getControl("digit3").getText() +
            this.getControl("digit4").getText() +
            this.getControl("digit5").getText() +
            this.getControl("digit6").getText());
        cb(true, r);
    }
    OTPVerificationView.prototype.handleStepErrors = function (errors) {
        OTPVerificationView.SUPERclass.handleStepErrors.call(this,errors);
        console.log(JSON.stringify(errors));
        var msg_error = this.getControl("msg_error");
        if(msg_error && errors[":error"]){
            var errMsg = errors[":error"];
            if (errMsg == ":code-doesnt-match"){
                errMsg = this._T(":otp-verification",":error-message")
                msg_error.setText(errMsg);
            }
            else {
                msg_error.setText(this._T(this.tkey,":send-error"));
            }
            msg_error.setVisibility(true);
        }
    }
    OTPVerificationView.prototype.hasActionBarSupport = function () {
        return true;
    }

    prisma_plugins = true;
})();
