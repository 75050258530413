(function() {


    function api(server, port, appToken, protocol) {
        this.server = server;
        this.port = port || "8080";
        this.appToken = appToken;
        this.protocol = protocol || "http:";
        this.rpc = new easyXDM.Rpc({remote: this.getUrl() + "/scripts/cors/index.html"},
                                  {remote: {request: {}}
                                  });
    }

    api.prototype.getUrl = function() {
        return prisma.format("{0}//{1}:{2}/",this.protocol, this.server, this.port);
    };

    api.prototype.post = function(url, data, onSuccess, onError) {
        var self = this;
        var headers = {"Prisma-Version": (prisma.version || "")};
        self.rpc.request({
            url: "../.." + url,
            method: "POST",
            timeout: 60000,
            headers: headers,
            data: jsedn.encode(data)
        }, function(response){
            onSuccess(response.data);
        }, function(a,b,c) {
            onError(a);
        });
    };

    api.prototype.get = function(url, parameters, onSuccess, onError, headers) {
        var self = this;

        var headers = headers || {};
        headers["Prisma-Version"] = (prisma.version || "");
        var queryString = [];
        for (var name in parameters) {
            if (parameters.hasOwnProperty(name)) {
                queryString.push(name + "=" + parameters[name]);
            }
        }
        var q = queryString.join("&");
        var url = q.length > 0 ? url + (~url.indexOf('?') ? '&' : '?') + q : url;
        if (!/^(f|ht)tps?:\/\//i.test(url)){
            url = "../.." + url;
        }
        self.rpc.request({
            url: url,
            method: "GET",
            headers: headers
        }, function(response){
            onSuccess(response.data);
        }, function(a, b, c) {
            onError(a);
        });

    };

    if (typeof prisma !== 'object') {
        prisma = {};
    }
    prisma_api = api;
    prisma.api = api;
    prisma_default_api = api;

})();
