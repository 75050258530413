(function () {

    var IN_PLACE = ":display-in-place";
    var POPUP = ":display-in-popup";
    var NEW_WINDOW = ":display-in-new-window";

    function enableCheckbox(checkbox) {
        var nodes = checkbox.label.childNodes;
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            if (node instanceof HTMLElement) {
                node.removeAttribute("disabled");
                node.classList.remove("disabled");
                node.style.cursor = null;
                node.style.opacity = null;
            }
        }
        checkbox.label.title = "";
        checkbox.setEnabled(true);
    }

    function parseSizeProp(in_value, defval) {
        if (!in_value) {
            in_value = defval;
        } else if (typeof in_value === "string") {
            try {
                in_value = parseInt(in_value.match(/\d+/));
            } catch (_) {
                in_value = defval;
            }
        }
        return in_value;
    }

    function TermsView(context, opts, childs) {
        TermsView.SUPERconstructor.call(this, context, opts, childs);
    }

    prisma.inherit(TermsView, prisma.widget);

    TermsView.prototype.fieldId = function (suffix) {
        return this.id + "-" + suffix;
    };

    TermsView.prototype.setupScrolling = function (element) {
        var self = this;
        if (self['force-scrolling']) {

            var endReached = function () {
                self.checkbox.checkbox.title = null;
                enableCheckbox(self.checkbox);
            };

            var reachedTheEnd = function () {
                // true when user reaches 90% of the container
                return element.scrollTop >= Math.floor(0.9 * (element.scrollHeight - element.clientHeight));
            };

            function onScroll(e) {
                if (reachedTheEnd()) {
                    endReached();
                    element.removeEventListener("scroll", onScroll);
                }
            }

            // check boundaries AFTER the UI is ready
            setTimeout(function () {
                if (reachedTheEnd()) {
                    endReached();
                } else {
                    prisma.setEvent(element, "scroll", onScroll);
                }
            }, 300);
        }
    };

    TermsView.prototype.clickHandler = function (e) {
        var self = this;

        if (!self.popup) {
            var width =  Math.min(self.boxSize.width, Math.floor(window.innerWidth * 0.9));
            var height =  Math.min(self.boxSize.height, Math.floor(window.innerHeight * 0.9));

            var container = document.createElement("div");
            prisma.addClass(container, "prisma-terms-in-popup");
            container.style.height = height + "px";
            container.style.overflowY = 'auto';

            container.appendChild(self.body);

            self.popup = new prisma.PopUp(self.container, width, height, false, {});
            self.popup.contentDiv.appendChild(container);
            self.setupScrolling(container); //self.popup.contentDiv
        }

        self.popup.show();

        return e.preventDefault();
    };

    TermsView.prototype.addTextToCheckboxLabel = function (text) {
        if (text === "") return;
        var span = document.createElement('span');
        span.innerText = text;
        if (this['force-scrolling']) {
            span.classList.add('disabled');
            span.style.cursor = "not-allowed";
            span.style.opacity = ".5";
        }
        this.checkbox.label.appendChild(span);
    };

    TermsView.prototype.addLinkToCheckboxLabel = function (text) {
        var self = this;
        var link = document.createElement("a");
        link.innerText = text;
        link.href = this['display-mode'] === NEW_WINDOW ? this['external-page-link'] : "#";
        if (this['display-mode'] === POPUP) {
            prisma.setEvent(link, "click", function(e) {
                self.clickHandler(e);
            });
        } else {
            link.target = "blank";
        }
        if (this['force-scrolling']) {
            link.style.opacity = ".8"; // so it doesn't stand out too much from the disabled text
        }
        this.checkbox.label.appendChild(link);
    };

    TermsView.prototype.createBody = function () {
        return new prisma.text(this.context, {
            id: this.fieldId("text"),
            text: this.text
        }, []).create();
    };

    TermsView.prototype.create = function () {
        var self = this;
        self.boxSize = {}; // nothing, yet

        var displayMode = this['display-mode'];
        var needsBody = (displayMode === IN_PLACE || displayMode === POPUP);

        // everything is put inside a container
        var container = document.createElement("div");
        container.id = self.id;

        var body = needsBody ? self.createBody() : undefined;

        if (displayMode === IN_PLACE) {
            self.boxSize = self['box-size'] || {};
            body.style.width = self.boxSize.width; // already in the format ###px
            body.style.height = self.boxSize.height; // already in the format ###px
            body.style.overflowY = "auto";
            body.style.maxWidth = "100%";
            container.appendChild(body);
            self.setupScrolling(body);
        } else if (displayMode === POPUP) {
            // size of the popup defaults to 1/4 of the total screen area
            self.boxSize = self['popup-size'] || {};
            // popup requires width and height to be integers
            self.boxSize.width = parseSizeProp(self.boxSize.width,
                                               (window.innerWidth || window.screen.width) * 0.5);
            self.boxSize.height = parseSizeProp(self.boxSize.height,
                                                (window.innerHeight || window.screen.height) * 0.5);
        }

        // make sure we force scroll only when scrolling is possible
        self['force-scrolling'] = self['force-scrolling'] && needsBody && !!self.boxSize.height;

        var checkbox = new prisma.checkbox(self.context, {
            id: self.fieldId("checkbox"),
            mode: "checkbox",
            storage_key: "terms_accepted",
            context_registration: false,
            label: self['checkbox-label'],
            required: true,
            enabled: !self['force-scrolling'],
            title: self['force-scrolling'] ? "Please read the Term & Conditions before accepting." : "",
            form_alignment: "vertical"
        }, []);

        var checkboxDiv = document.createElement("div");
        checkboxDiv.className = "prisma-datacapture-component";
        checkboxDiv.appendChild(checkbox.create());
        container.appendChild(checkboxDiv);

        self.body = body;
        self.checkbox = checkbox;
        self.container = container;

        // process the checkbox label in order to insert the link which will open the T&C window
        if (displayMode === POPUP || displayMode === NEW_WINDOW) {
            var matches = self['checkbox-label'].match(/(.*){([^{}]+)}([^}].*)/); // /(.*){(.+)}(.*)/
            if (matches) {
                // text is the last element (first element is the checkbox)
                checkbox.label.removeChild(checkbox.label.lastChild);
                // text before the open bracket
                self.addTextToCheckboxLabel(matches[1]);
                // text inside brackets
                self.addLinkToCheckboxLabel(matches[2]);
                // text after the close bracket
                self.addTextToCheckboxLabel(matches[3]);
                if (self['force-scrolling']) {
                    // trick needed to make the link visually appealing
                    prisma.removeClass(checkbox.label, "disabled");
                }
            }
        }

        return container;
    };

    TermsView.prototype.retrieveData = function (cb) {
        this.checkbox.retrieveData(cb);
    };

    prisma.terms_and_conditions = TermsView;
})();
