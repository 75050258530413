(function () {
   
    function PhoneMailValidationView(def, funnel) {
        PhoneMailValidationView.SUPERconstructor.call(this, def, funnel);
    }

    prisma.registerPlugin(jsedn.kw(":built-in/phone-email-verification"), PhoneMailValidationView,prisma.Page);

    PhoneMailValidationView.prototype.AsyncVerificationBehavior = function (definition){
        var sendMessageBtn = this.getControl("send-message");
        var cancelPanel = this.getControl("cancel-panel");
        var messageSended = this.getControl("message-sended");
        var msg_error = this.getControl("msg_error");
        var cancel = this.getControl("cancel");
        var cancelled_view = this.getControl("cancelled-view");
        var main_view = this.getControl("main-view");
        var self = this;
        messageSended.setVisibility(false);
        cancelPanel.setVisibility(false);
        msg_error.setVisibility(false);
        cancelled_view.setVisibility(false);

        var sendButtonWait = function (tmout){
            sendMessageBtn.setEnabled(false);
            sendMessageBtn.setText(prisma.format(self._T(self.tkey,":re-send-message-button"), tmout));
            var ith = setInterval(function (){
                tmout--;
                sendMessageBtn.setText(prisma.format(self._T(self.tkey,":re-send-message-button"), tmout));
                if(tmout == 0){
                    clearInterval(ith);
                    sendMessageBtn.setText (self._T(self.tkey,":send-message-button"));
                    sendMessageBtn.setEnabled(true);
                }
            }, 1000);
        }
        if(definition[":last-send-timestamp"]){
            var tmout = parseInt(((definition[":last-send-timestamp"] + 120000 ) - Date.now())/1000);
            sendButtonWait(tmout);
            messageSended.setVisibility(true);
            cancelPanel.setVisibility(true);
        }
        cancel.onClick(function(e){
            var r = confirm(self._T(self.tkey,":cancel-verification-confirmation"))
            if(r == true){
                self.setWaitState(true);
                self.funnel.restRequest(new jsedn.Map([new jsedn.kw(":action"),new jsedn.kw (":cancel-verification")]),

                function (data){
                    self.setWaitState(false);
                    if (data[":status"] == true) {
                        if (!self.funnel.showInModal()) {
                            self.getControl("btnclose").setVisibility(false);
                        }

                        main_view.setVisibility(false);
                        cancelled_view.setVisibility(true);

                    } else
                        alert("ERROR " + error);
                },
                function (error){
                    self.setWaitState(false);
                    alert("ERROR " + error);
                })

            }

        });

        sendMessageBtn.onClick(function(e){
            self.setWaitState(true);
            msg_error.setVisibility(false);
            self.funnel.restRequest(new jsedn.Map([new jsedn.kw(":action"),new jsedn.kw (":send-a-link"),
                                                   new jsedn.kw(":timestamp"), Date.now()]),
                function (data){
                    self.setWaitState(false);
                    if(data[":status"] == ":sent"){
                        messageSended.setVisibility(true);
                        cancelPanel.setVisibility(true);
                        sendButtonWait(120)
                    }else {
                        cancelPanel.setVisibility(true);
                        msg_error.setText(self._T(self.tkey,":send-error"));
                        msg_error.setVisibility(true);
                    }
                },function (error){
                    self.setWaitState(false);
                    cancelPanel.setVisibility(true);
                    msg_error.setText(self._T(self.tkey,":send-error"));
                    msg_error.setVisibility(true);
                })
        })
    }
    
    PhoneMailValidationView.prototype.createContent = function (definition) {
        var self = this;
        this.definition = definition;
        var body = PhoneMailValidationView.SUPERclass.createContent.call(this,definition[":layout"])
        if (definition[":validation-type"] == ":cellular-number"){
            if(definition[":phone-validation-mode"] == ":send-a-code"){
                this.tkey = ":phone-validation-by-code";
                var sendCodeBtn = this.getControl ("send-code");
                var verifyBtn = this.getControl ("verify");
                var digit1 = this.getControl("digit1");
                var digit2 = this.getControl("digit2");
                var digit3 = this.getControl("digit3");
                var digit4 = this.getControl("digit4");
                var digit5 = this.getControl("digit5");
                var digit6 = this.getControl("digit6");
            
                var cancel = this.getControl("cancel");
                var cancelled_view = this.getControl("cancelled-view")
                var main_view = this.getControl("main-view")
                var msg_error = this.getControl("msg_error");
                verifyBtn.setEnabled(false);
                cancelled_view.setVisibility(false);
                var checkCodeCompletion = function(){
                    msg_error.setVisibility(false);
                    verifyBtn.setEnabled(digit1.getText()!="" &&
                                         digit2.getText()!="" &&
                                         digit3.getText()!="" &&
                                         digit4.getText()!="" &&
                                         digit5.getText()!="" && 
                                         digit6.getText()!="");
                }

                digit1.onChange(function(){checkCodeCompletion();digit2.setFocus(false)});
                digit2.onChange(function(){checkCodeCompletion();digit3.setFocus(false)});
                digit3.onChange(function(){checkCodeCompletion();digit4.setFocus(false)});
                digit4.onChange(function(){checkCodeCompletion();digit5.setFocus(false)});
                digit5.onChange(function(){checkCodeCompletion();digit6.setFocus(false)});
                digit6.onChange(function(){checkCodeCompletion();verifyBtn.setFocus(false)});

                cancel.onClick(function(e){
                    var r = confirm(self._T(self.tkey,":cancel-verification-confirmation"))
                    if(r == true){
                        self.setWaitState(true);
                        self.funnel.restRequest(new jsedn.Map([new jsedn.kw(":action"),new jsedn.kw (":cancel-verification")]),

                        function (data){
                            self.setWaitState(false);
                            if(data [":status"] == true){
                                if(!self.funnel.showInModal()){
                                    self.getControl("btnclose").setVisibility(false);
                                }
                                main_view.setVisibility(false);
                                cancelled_view.setVisibility(true);
                            }else
                                alert("ERROR " + error);
                        },
                        function (error){
                            self.setWaitState(false);
                            alert("ERROR " + error);
                        })
    
                    }

                });
                var sendButtonWait = function (tmout){
                    sendCodeBtn.setEnabled(false);
                    sendCodeBtn.setText(prisma.format(self._T(self.tkey,":re-send-code-button"), tmout));
                    var ith = setInterval(function (){
                        tmout--;
                        sendCodeBtn.setText(prisma.format(self._T(self.tkey,":re-send-code-button"), tmout));
                        if(tmout == 0){
                            clearInterval(ith);
                            sendCodeBtn.setText (self._T(self.tkey,":send-code-button"));
                            sendCodeBtn.setEnabled(true);
                        }
                    }, 1000);
                }
                msg_error.setVisibility(false);
                if(definition[":last-send-timestamp"]){
                    var tmout = parseInt(((definition[":last-send-timestamp"] + 120000 ) - Date.now())/1000);
                    sendButtonWait(tmout);
                }
                sendCodeBtn.onClick(function(e){
                    msg_error.setVisibility(false);
                     self.setWaitState(true);
                     self.funnel.restRequest(new jsedn.Map([new jsedn.kw(":action"),new jsedn.kw (":send-phone-code"),
                                                            new jsedn.kw(":timestamp"), Date.now()]),
                        function (data){
                            self.setWaitState(false);
                            if(data[":status"] == ":sent"){
                                digit1.setFocus();
                                sendButtonWait(120)
                            }else {
                                msg_error.setText(self._T(self.tkey,":send-error"));
                                msg_error.setVisibility(true);
                            }
                        },function (error){
                            self.setWaitState(false);
                            msg_error.setText(self._T(self.tkey,":send-error"));
                            msg_error.setVisibility(true);
                        })
                })
            } else {
                this.tkey = ":phone-validation-by-link";
                this.AsyncVerificationBehavior(definition);
            }

        } else {
            this.tkey = ":email-validation";
            this.AsyncVerificationBehavior(definition);
        }
        return body;
    };
    PhoneMailValidationView.prototype.retrieveData = function (cb) {
        if (this.definition[":validation-type"] == ":cellular-number") {
            if (this.definition[":phone-validation-mode"] == ":send-a-code") {
                var r = new jsedn.Map();

                r.set(new jsedn.kw(":code"), this.getControl("digit1").getText() +
                    this.getControl("digit2").getText() +
                    this.getControl("digit3").getText() +
                    this.getControl("digit4").getText() + 
                    this.getControl("digit5").getText() + 
                    this.getControl("digit6").getText());
                cb(true, r);
            }else {
                cb(true,{})
            }
        }else {
            cb(true,{})
        }
    }
    PhoneMailValidationView.prototype.handleStepErrors = function (errors) {
        console.log(JSON.stringify(errors));
        PhoneMailValidationView.SUPERclass.handleStepErrors.call(this,errors);
        var msg_error = this.getControl("msg_error");
        if(msg_error && errors[":error"]){
            var errMsg = errors[":error"];
            if (errMsg == ":phone-validation-code-doesnt-match"){
                errMsg = this._T(":phone-validation-by-code",":error-message")
                msg_error.setText(errMsg);
            }
            else {
                msg_error.setText(this._T(this.tkey,":send-error"));
            }
            msg_error.setVisibility(true);
        }
    }
    PhoneMailValidationView.prototype.hasActionBarSupport = function () {
        return true;
    }

    prisma_plugins = true;
})();