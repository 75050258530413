(function(){
    function DarientView (def,funnel) {
        DarientView.SUPERconstructor.call(this,def,funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/darient"), DarientView);

    DarientView.prototype.wasRedirected = function () {
        return this.redirected;
    };

    DarientView.prototype.createContent = function (definition) {
        var translations = this.funnel && this.funnel.triggeringParent && this.funnel.triggeringParent.translations;
        if (translations) {
            this.progress_title = prisma._T(":identity-validation", ":progress-title", translations);
            this.progress_message = prisma._T(":identity-validation", ":progress-body", translations);
        } else {
            this.progress_title = "In progress";
            this.progress_message = "Identity validation is still in progress";
        }

        var req = definition[":darient-request"];
        var scanReference = definition[":scan-reference"];

        var error = definition[":darient-error"]
        if (error) {
          this.error = true;
          var body = document.createElement("div");
          var message_display_div = document.createElement("div");
          message_display_div.className = "message-display";

          var title_node = document.createElement("h1");
          title_node.className = "prisma-title-component";
          title_node.appendChild(document.createTextNode("Error"));

          var text_node = document.createElement("div");
          text_node.innerHTML = definition[":error-message"]
          text_node.className = "prisma-text-component";
          message_display_div.appendChild(title_node);
          message_display_div.appendChild(text_node);

          body.appendChild(message_display_div);
          return body;
        }


        if (this.getWaitState()) {
            var body = document.createElement("div");
            var message_display_div = document.createElement("div");
            message_display_div.className = "message-display";

            var title_node = document.createElement("h1");
            title_node.className = "prisma-title-component";
            title_node.appendChild(document.createTextNode(this.progress_title));

            var text_node = document.createElement("div");
            text_node.innerHTML = this.progress_message;
            text_node.className = "prisma-text-component";
            message_display_div.appendChild(title_node);
            message_display_div.appendChild(text_node);

            this.reference = scanReference;
            body.appendChild(message_display_div);
            return body;
        } else {
            var url = req[":darient-url"];
            this.reference = req[":transactionReference"];
            var onRedirect = this.funnel.handler.onRedirect;
            if (onRedirect) {
                onRedirect(url);
            } else {
              if (typeof(this.funnel.sandboxHash) != "undefined") {
                 window.location.href = url;
              } else {
                window.top.location.href = url;
              }
            }

            this.redirected = true;
            this.redirectedUrl = url;
        }
    };

   DarientView.prototype.retrieveData = function (cb){
        var r = new jsedn.Map();
        if (this.error) {
          cb(true, r);
          return;
        }
        r.set(jsedn.kw(":scan-reference"), this.reference)
        cb(true, r);
    }

    prisma_plugins = true;
})();
