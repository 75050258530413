(function() {

	function trim(s) {
		if (typeof (s) === "string"){

			var l=0;var r=s.length -1;
			while(l < s.length && (s[l] == ' ' || s[l]=='\n' || s[l]=='\r'))
			{l++;}
			while(r > l && (s[r] == ' ' || s[l]=='\n' || s[l]=='\r'))
			{r-=1;}
			return s.substring(l, r+1);
		}
		return s;
	}
	var dateFormat = prisma.dateFormat || "dd/MM/yyyy";
	var dateMask = "00/00/0000";

	function dateToLocal (d){
		var format = dateFormat;
		var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		if(d){
			switch(format){

				case "dd-MM-yyyy":
					return (d.getUTCDate()>9?d.getUTCDate():'0'+d.getUTCDate())+"-"+(d.getUTCMonth()+1>9?d.getUTCMonth()+1:'0'+(d.getUTCMonth()+1))+"-"+d.getUTCFullYear();
				break;
				case "dd-MMM-yyyy":
					return (d.getUTCDate()>9?d.getUTCDate():'0'+d.getUTCDate())+"-"+months[d.getUTCMonth()]+"-"+d.getUTCFullYear();
				break;

				case "dd/MM/yyyy":
					return (d.getUTCDate()>9?d.getUTCDate():'0'+d.getUTCDate())+"/"+(d.getUTCMonth()+1>9?d.getUTCMonth()+1:'0'+(d.getUTCMonth()+1))+"/"+d.getUTCFullYear();
				break;

				case "MM-dd-yyyy":
					return (d.getUTCMonth()+1>9?d.getUTCMonth()+1:'0'+(d.getUTCMonth()+1))+"-"+(d.getUTCDate()>9?d.getUTCDate():'0'+d.getUTCDate())+"-"+d.getUTCFullYear();
				break;

				case "MM/dd/yyyy":
					return (d.getUTCMonth()+1>9?d.getUTCMonth()+1:'0'+(d.getUTCMonth()+1))+"/"+(d.getUTCDate()>9?d.getUTCDate():'0'+d.getUTCDate())+"/"+d.getUTCFullYear();
				break;

			}
		}
	}
	function sanitizeDateStr (dateStr){
		if (!dateStr)
			return dateStr;
		var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		dateStr = dateStr.toLowerCase();
		for (k in months){
			var r = months[k];
			try{
			  dateStr = dateStr.replace(r.toLowerCase(),k<=9?'0'+(parseInt(k)+1):parseInt(k)+1);
			}catch(e){
			  return dateStr;
			}
		}
		return dateStr;
	}


	function isSpecialKey (charCode){
		return (
			charCode === 9	|| // tab
			charCode === 13	|| // enter
			charCode === 16	|| // shift
			charCode === 17	|| // ctrl
			charCode === 18	|| // alt
			charCode === 20	|| // caps lock
			charCode === 27	|| // escape
			charCode === 33	|| // page up
			charCode === 34	|| // page down
			charCode === 35	|| // home
			charCode === 36	|| // end
			charCode === 37	|| // left arrow
			charCode === 38	|| // up arrow
			charCode === 39	|| // right arrow
			charCode === 40	|| // down arrow
			charCode === 45	|| // insert
			charCode === 144	|| // num lock
			charCode > 256 // Safari strange bug
		);
	}
	var setCaretPosition = function(control,startPos, endPos){
		var valLength = control.value.length;
		if(isNaN(parseInt(startPos,10))){
			return false;
		} else {
			startPos = parseInt(startPos,10);

			if(startPos < 0){
				startPos = 0;
			} else if(startPos > valLength){
				startPos = valLength;
			}
		}

		if(isNaN(parseInt(endPos,10)) || parseInt(endPos,10) < startPos){
			endPos = startPos;
		} else {
			endPos = parseInt(endPos,10);

			if(endPos < 0){
				endPos = 0;
			} else if(endPos > valLength){
				endPos = valLength;
			}
		}

		if(typeof(control.createTextRange) === "object"){
			// IE
			var range = control.createTextRange();
			range.moveEnd("character", endPos - control.value.length);
			range.moveStart("character", startPos);
			range.select();

			return true;
		} else if (typeof(control.setSelectionRange) === 'function'){
			// mozilla, opera, safari
			control.setSelectionRange(startPos, endPos);
			return true;
		}

		return false;
	};
	var getSelectionStart = function(control){
		if (typeof(control.selectionStart) !== "undefined") {
			// mozilla and opera
			var selStart = control.selectionStart;

			// Safari bug when field is focused for first time
			if(selStart === 2147483647){
				selStart = 0;
			}
			return selStart;
		} else if (document.selection) {
			// IE black magic
			return Math.abs(
				document.selection.createRange().moveStart("character", -1000000)
			);
		}

		return null;
	};
	var getSelectionEnd = function(control){
		if (typeof(control.selectionEnd) !== "undefined") {
			// mozilla and opera
			return control.selectionEnd;
		} else if (document.selection) {
			// IE black magic
			return control.value.length - Math.abs(
				document.selection.createRange().moveEnd("character", 1000000)
			);
		}
		return null;
	};


	var maskInput ;
	maskInput = function( control,mask,onchange,onblur){
		var self = this;
		var tmp;
		this.changecb = onchange;
		this.blurcb = onblur;
		var tstval = 20;
		if(mask == "date"){
			this.mask = dateMask;
			this.convertout = function (value){
				return self.getUmaskedValue();
			};
			this.convertin = function (value){
				if (value instanceof Date){
					value = dateToLocal(value);
				}
				return value;
			};

		}else {
			this.mask = mask;
			this.convertout = function (value){
				return value;
			};
			this.convertin = function (value){
				return value;
			};
		}
		this.control = control;
		this.initMask();
		prisma.setEvent(this.control,"keypress",function(e){
			if(self.readOnly){
				e.preventDefault();
				return false;

			}
			if(!self.keyhandler(e)){
				e.preventDefault();
				return false;
			}else
				return true;
		});
		prisma.setEvent(this.control,"keydown",function(e){
		    if(self.tst){
    			e.preventDefault();
		        return false;
		    }
            self.tst = setTimeout(function(){
                self.tst = null;
            },tstval);

			if(self.readOnly){
				e.preventDefault();
				return false;
			}

			var key = e.which!==0?e.which:e.keyCode;
			self.fireOnChange();
			if(isSpecialKey(key)){
				return true;
			}
			if (!self.processCustomKeys(key)){
				e.preventDefault();
				return false;
			}else
				return true;
		});
		prisma.setEvent(this.control,"keyup",function(e){
			if(self.readOnly){
				e.preventDefault();
				return false;
			}

			var key = e.which!==0?e.which:e.keyCode;
			if(isSpecialKey(key)){
				return true;
			}
			e.preventDefault();
			return false;
		});


		prisma.setEvent(this.control,"blur",function(e){
			if (self.isEmpty()){
				self.control.value = "";
			}
			var newval = self.control.value;

			if(self.fire_on_change_timeout){
			  clearTimeout(self.fire_on_change_timeout);
			  self.fire_on_change_timeout = null;
			}
			if(self.prevValue !== newval){
			  self.prevValue = newval;
			  self.changecb(self.convertout(newval));
			}
			setTimeout(
			  function(){
				self.blurcb();
			  },200);
			return true;
		});

		prisma.setEvent(this.control,"paste",function(){
			setTimeout(function(){
				self.applyMask(self.control.value);
			},1);
		});

		prisma.setEvent(this.control,"focus",function(e){
			if(!self.control.disabled && !self.control.readOnly){
				if(self.isEmpty()){
				  setTimeout(function(){
					self.applyMask("",true);
					setTimeout(function(){
						setCaretPosition(self.control,0);},10);
					},20);
				}
			}
		});
	};
	maskInput.prototype.fireOnChange = function(){
		var self = this;
		if(this.fire_on_change_timeout){
			clearTimeout(this.fire_on_change_timeout);
			this.fire_on_change_timeout = null;
		}
		this.fire_on_change_timeout = setTimeout(function(){
			self.fire_on_change_timeout = null;
			var newval = self.control.value;
			if(self.prevValue !== newval){
				self.prevValue = newval;
				self.changecb(self.convertout(newval));

			}
		},500);
	};

	maskInput.prototype.initMask=function(){
		var maskChars = this.mask.split('');
		this.chars = [];
		this.enteredValue = [];
		for(var ii = 0; ii < maskChars.length; ii++){
			tmp = null;
			switch(maskChars[ii]){

				case "0":
					tmp = "[0-9]";
					break;
				case "#":
					tmp = "[0-9s%.]*";
					break;
				case "L":
					tmp = "[a-zA-Z]";
					break;
				case "A":
					tmp = "[0-9a-zA-Z]";
					break;
				case "&":
					tmp = ".";
					break;
				case "\\":
					ii++;
					if(ii >= maskChars.length){
						break;
					}
						// fall through
				default:
					this.chars.push(maskChars[ii]);
					this.enteredValue.push(maskChars[ii]);
			}

			if(tmp !== null){
				var re = new RegExp("^" + tmp + "$");
				this.chars.push(re);
				this.enteredValue.push(null);
			}
		}
	};
	maskInput.prototype.getUmaskedValue = function(){
		var val="";
	//	if ( this.isMaskFullyFilled()){

			for(var ii = 0; ii < this.enteredValue.length; ii++){
				if(typeof(this.chars[ii]) !== 'string' && this.enteredValue[ii] !== null){
					val+=this.enteredValue[ii];
				}
			}

	//	}
		return val;
	};
	maskInput.prototype.isMaskFullyFilled = function(){
		for(var ii = 0; ii < this.enteredValue.length; ii++){
			if(typeof(this.chars[ii]) !== 'string' && this.enteredValue[ii] === null){
				return false;
			}
		}
		return true;
	};
	maskInput.prototype.applyMask=function(newVal,forced)
	{
		var ii;
		var val = "";
		var value = "";
		newVal = this.convertin(newVal);
		if(!forced && (newVal ==='' || newVal === null)){
			this.initMask();
			this.control.value = newVal;
			return;

		}
		newVal= newVal!==undefined?newVal:'';

	// 	newVal = trim(newVal);
		// formatea newVal segun la mascara
		var i=0;
		for(ii = 0; ii < this.chars.length; ii++){
			if(i < newVal.length){
				if(typeof(this.chars[ii]) !== "string"){
					value+=newVal.charAt(i++);
				}else{
					value+= this.chars[ii];
					if(this.chars[ii]===newVal.charAt(i)){
						i++;
					}
				}
			}
		}

		for(ii = 0; ii < this.chars.length; ii++){
			if(ii < value.length){
				// if value is given - fill this.enteredValue with it.
				if(typeof(this.chars[ii]) !== "string"){
					if((ii == 0 && value.charAt(ii) == " ") || this.chars[ii].test(value.charAt(ii))){
						this.enteredValue[ii] = value.charAt(ii);
						val += value.charAt(ii);
					} else {
						this.enteredValue[ii] = null;
						val += "_";
					}
				} else {
					this.enteredValue[ii] = this.chars[ii];
					val += this.chars[ii];
				}
			} else if(arguments.length > 2){
				// if value were given - clear rest of the characters
				if(typeof(this.chars[ii]) === 'string'){
					val += this.chars[ii];
				} else {
					this.enteredValue[ii] = null;
					val += "_";
				}
			} else {
				// if no value were given - then form masked value from internal arrays
				if(typeof(this.chars[ii]) === 'string'){
					val += this.chars[ii];
				} else {
					val += this.enteredValue[ii] === null ? "_" : this.enteredValue[ii];
				}
			}
		}
		if(forced || this.control.value !== val && this.prevValue !== val )
			this.control.value = val;
	};






	maskInput.prototype.keyhandler = function(e) {
		var newPos;
		var self = this;
		var charCode = e.which;
		var newChar = String.fromCharCode(charCode);

		var pos = getSelectionStart(this.control);


	// 	if(icc.utils.browser.is_opera && e.originalEvent.which === 0){
	// 		return true;
	// 	}
		if(e.which === 0){
			return true;
		}
		if(this.control.readOnly){
			return false;
		}

		if(newChar === "-"){
			if(pos == 6 && this.enteredValue[pos] === null && this.chars[7] === "-"){
				this.enteredValue.splice(pos,1);
				this.enteredValue.unshift(" ");
				this.applyMask();
				setCaretPosition(this.control,pos+1);
				return false;
			}
		}
		// if char under cursor is strict - search for next mask char.
		// If no such char founded - leave at current position and exit
		if(typeof(this.chars[pos]) === 'string'){
			newPos = this.getNextAvailablePosition(pos);

			if(newPos === null || newPos === pos){
				return false;
			}
			setCaretPosition(this.control,newPos);
			pos = newPos;
		}

		// check if entered char could be applied to current mask element.
		if(
			pos >= this.chars.length ||
			typeof(this.chars[pos]) !== 'string' && !newChar.match(this.chars[pos]) ||
			typeof(this.chars[pos]) === 'string' && newChar !== this.chars[pos]
		){
			this.applyMask();
			setCaretPosition(this.control,pos);
		} else {
			this.enteredValue[pos] = newChar;
			this.applyMask();
			newPos = this.getNextAvailablePosition(pos);
			if(newPos === null){
				newPos = pos + 1;
			}
			setCaretPosition(this.control,newPos);
		}
		return false;
	};

	maskInput.prototype.processCustomKeys = function(charCode){
		var selStart = getSelectionStart(this.control);
		var selEnd = getSelectionEnd(this.control);
		if(this.control.readOnly){
			return false;
		}

		if(selStart === selEnd){
			if(charCode === 8){ // backspace
				var newPos = this.getPrevAvailablePosition(selStart);

				if(newPos === null || newPos === selStart){
					return false;
				}

				this.enteredValue[newPos] = null;
				this.applyMask();
				setCaretPosition(this.control,newPos );
				return false;
			}

			if(charCode === 46){ // delete
				if(typeof(this.chars[selStart]) === 'string'){
					return false;
				}

				this.enteredValue[selStart] = null;
				this.applyMask();
				setCaretPosition(this.control,selStart);

				return false;
			}
		} else {
			if(charCode === 8 || charCode === 46){ // backspace
				for(var ii = selStart; ii < selEnd; ii++){
					if(typeof(this.chars[ii]) !== 'string'){
						this.enteredValue[ii] = null;
					}
				}
				this.applyMask();
				setCaretPosition(this.control,selStart);

				return false;
			}
		}

		return true;
	};
	maskInput.prototype.getNextAvailablePosition = function(pos){
		if(pos + 1 >= this.enteredValue.length){
			return null;
		}
		if(typeof(this.chars[pos + 1]) === 'string'){
			return this.getNextAvailablePosition(pos + 1);
		}
		return (pos + 1);
	};

	maskInput.prototype.getPrevAvailablePosition = function(pos){
		if(pos - 1 < 0){
			return null;
		}
		if(typeof(this.chars[pos - 1]) === 'string'){
			return this.getPrevAvailablePosition(pos - 1);
		}
		return pos - 1;
	};

	maskInput.prototype.isEmpty = function(){
		for(var ii = 0; ii < this.enteredValue.length; ii++){
			if(typeof(this.chars[ii]) !== 'string' && this.enteredValue[ii] !== null){
				return false;
			}
		}
		return true;
	};

	maskInput.prototype.getValue = function(){
		if(trim(this.getUmaskedValue()).length!==0){
			return this.control.value;
		}else{
			return "";
		}
	};
	maskInput.prototype.setValue = function (value){
		if(value === null)
			value = "";
		this.applyMask(value);
		this.prevValue = value;

	};


	maskInput.prototype.setReadOnly = function(value){
		this.readOnly = value;
	};


	var numericInput ;
	numericInput = function( control,maxLength,decimals,currency,allowNegative,decimalSeparator,thousandsSeparator,onchange,onblur){
		var self = this;
		var tmp;
		this.changecb = onchange;
	    this.blurcb = onblur;
		this.maxLength=maxLength||10e6;
		this.decimals = decimals!==null?decimals:0;
		this.decimalSeparator = decimalSeparator!==null?decimalSeparator:".";
		this.thousandsSeparator = thousandsSeparator!==null?thousandsSeparator:",";
		this.allowNegative = allowNegative!==null?allowNegative:true;
        this.decimalPos = 0;
		this.currency = currency;
		this.control = control;
        var tstval = 20;
		this.enteredValue = [];
		if(trim(this.control.value)!==''){

			this.applyMask(this.control.value);
		}
		prisma.setEvent(this.control,"keypress",function(e){
			if(self.readOnly ){
				e.preventDefault();
				return false;
			}
			if(!self.keyhandler(e)){
				e.preventDefault();
				return false;
			}else
				return true;

		});
		prisma.setEvent(this.control,"keydown",function(e){
		    if(self.tst){
    			e.preventDefault();
		        return false;
		    }
            self.tst = setTimeout(function(){
                self.tst = null;
            },tstval);

			if(self.readOnly){
				e.preventDefault();
				return false;
			}

			var key = e.which!==0?e.which:e.keyCode;
			if(isSpecialKey(key)){
				return true;
			}
			self.fireOnChange();
			if(!self.processCustomKeys(key)){
				e.preventDefault();
				return false;
			}else
				return true;

		});
		prisma.setEvent(this.control,"keyup",function(e){
			if(self.readOnly ){
				e.preventDefault();
				return false;
			}

			var key = e.which!==0?e.which:e.keyCode;
			if(isSpecialKey(key)){
				return true;
			}
			e.preventDefault();
			return false;
		});
		prisma.setEvent(this.control,"paste",function(){
			setTimeout(function(){
				self.applyMask(self.control.value);
			},1);
		});
		prisma.setEvent(this.control,"blur",function(e){

			if(self.readOnly){
			    e.preventDefault();
				return false;
			}
 		    var newval = self.control.value;
			if(self.fire_on_change_timeout){
			    clearTimeout(self.fire_on_change_timeout);
				self.fire_on_change_timeout = null;
			}
		    if(self.prevValue !== newval){
			    self.prevValue = newval;
			    self.changecb(self.convertOutput(newval));
		    }
		  setTimeout(
			function(){
			  self.blurcb();
			},200);
			  return true;
		});
	};
	numericInput.prototype.fireOnChange = function(){
		var self = this;
		if(this.fire_on_change_timeout){
			clearTimeout(this.fire_on_change_timeout);
			this.fire_on_change_timeout = null;
		}
		this.fire_on_change_timeout = setTimeout(function(){
			self.fire_on_change_timeout = null;
			var newval = self.control.value;
			if(self.prevValue !== newval){
				self.prevValue = newval;
				self.changecb(self.convertOutput(self.getValue()));
			}
		},500);
	};

	numericInput.prototype.getValue = function(){
		return this.control.value.replace(new RegExp(this.thousandsSeparator,"g"),'');
	};
	numericInput.prototype.setValue = function (value){
		this.applyMask(value);
		this.prevValue = value;

	};

	numericInput.prototype.isMaskFullyFilled = function(){
		return !this.isEmpty();
	};
	numericInput.prototype.convertOutput = function (controlData){
	  return controlData;
	};
	numericInput.prototype.applyMask=function(newVal)
	{
		var val = "";
		var t;
		var value;
		this.decimalPos = 0;
		this.thousandsChars = 0;
		var decimalsCount=0;
		var colonCount=0;
		this.enteredValue=[];
		newVal = ''+newVal;
		newVal = newVal.replace(new RegExp(this.thousandsSeparator,"g"),'');
		if(this.decimals > 0){
            this.value = parseFloat(newVal);

            if(isNaN(this.value) ){
                this.control.value = "";
                return;
            }
        }else{
            this.value = newVal;
        }
		newVal = ''+this.value;
		if(parseFloat(newVal) <0  && this.negative === undefined){
			this.negative = true;
		}else{
			this.negative=false;
		}
		t = newVal.split('');
		for (var ii=0;ii<t.length;ii++){
			if(t[ii]===this.decimalSeparator){
				this.decimalPos=ii;
				this.enteredValue[ii]=t[ii];
			}else {
				if(this.decimalPos!==0){

					if(decimalsCount<this.decimals){
						decimalsCount++;
						this.enteredValue[ii]=t[ii];
					}
				}else{
					this.enteredValue[ii]=t[ii];
				}
			}
		}
		if(this.decimals>decimalsCount){
			if(decimalsCount===0){
				this.enteredValue.push(this.decimalSeparator);
			}
			for(var ii = this.decimals-decimalsCount;ii>0;ii--){
				this.enteredValue.push("0");

			}

		}
		this.decimalPos=this.decimals;
		if(this.currency){// en modo currency recorre desde el punto decimal hacia abajo e inserta un "," cada 3
			var c=0;
			for (var ii=this.enteredValue.length-1-this.decimalPos;ii>=0;ii--){
				if(c>0 && c%3===0){
					if(ii>0 && this.enteredValue[ii-1]!=='-'){
						this.thousandsChars++;
						this.enteredValue.splice(ii,0,this.thousandsSeparator);

					}

				}
				c++;
			}
		}
		if(this.negative && value!==0 && this.enteredValue[0]!=='-'){
			this.enteredValue.splice(0,0,'-');
		}
		// sucio hack para sacar un ocasional . al final cuando no hay decimales
		if(this.decimals == 0 && this.enteredValue[this.enteredValue.length-1]==this.decimalSeparator)
			this.enteredValue.pop();

		var v = this.enteredValue.join('');
		this.control.value = v;
	};

	numericInput.prototype.keyhandler = function(e) {

		var self = this;
		var charCode = e.which;
		var newChar = String.fromCharCode(charCode);
		var pos;
		var lengthExceeded = function(length){
			if(length!=10e6){

				var realLength = self.control.value.length;

				var ccount = function (s,s1){
					return s.split(s1).length-1;
				};
				realLength -= self.decimals;
				realLength -= ccount(self.control.value,self.decimalSeparator);
				realLength -= ccount(self.control.value,self.thousandsSeparator);
				realLength -= ccount(self.control.value,'-');

				return realLength>length;
			}
			return false;
		};

	// 	if(icc.utils.browser.is_opera && e.originalEvent.which === 0){
	// 		return true;
	// 	}
		if(e.which === 0){
			return true;
		}

		if (String("1234567890-.").indexOf(newChar)===-1){
			return false;
		}
		pos = this.clearMultiSelection(getSelectionStart(this.control),getSelectionEnd(this.control));

		if(newChar === '.'){
			if(this.decimalPos>0){
				setCaretPosition(this.control,this.enteredValue.length - this.decimalPos);
			}
			return false;
		}
		if(newChar === '-'){
			if(this.allowNegative){
				this.negative = !this.negative;
				if(this.enteredValue[0] === '-'){
					this.enteredValue.splice(0,1);
				}else{
					this.enteredValue.splice(0,0,'-');
				}
				if(this.enteredValue.length == 1 && this.negative)
				    return true;
				this.applyMask(this.enteredValue.join(''));
				setCaretPosition(this.control,pos+(this.enteredValue[0]==='-'?1:-1));
			}
			return false;
		}
			//esta primera condicion cubre el caso de
			//que sea sin decimales y negativo
		if((pos===0 && this.negative && this.decimalPos===0) || (this.enteredValue.length - this.decimalPos) > pos){

			var c = this.thousandsChars;
			var l = this.enteredValue.length-this.thousandsChars;
			// si estoy en la posicion 0 y efectivamente mi numero es 0 no inserto
			// paso por arriba el 0 , tambien considero un signo negativo delante
			if((pos===0 || pos===(this.enteredValue[0]==='-'?1:0)) && this.enteredValue[this.enteredValue[0]==='-'?1:0]==='0'){

				pos = this.enteredValue[0]==='-'?1:0;
				this.enteredValue[this.enteredValue[pos]===this.decimalSeparator?pos-1:pos]=newChar;
				pos++;

			}else {
				if(pos ===0 && this.enteredValue[0] === '-'){
					pos++;
				}
				if(lengthExceeded(this.maxLength-1))
					return false;
				this.enteredValue.splice(pos,0,newChar);
			}

			this.applyMask(this.enteredValue.join(''));
			var nl = this.enteredValue.length-this.thousandsChars;


			pos+=(nl-l);
			pos+=this.thousandsChars-c;
		}else{

			if(this.enteredValue[pos]===this.decimalSeparator){
				pos++;
			}
			if(this.decimals == 0 && lengthExceeded(this.maxLength-1)){

				return false;
			}

			this.enteredValue[pos]=newChar;

			pos++;
			this.applyMask(this.enteredValue.join(''));
		}

		setCaretPosition(this.control,pos);

		e.preventDefault();
		return false;
	};


	numericInput.prototype.clearMultiSelection = function(selStart,selEnd){
		if (selStart === selEnd){
			return selStart;
		}
		var t = selStart;
		for (var ii = selStart ;ii<selEnd;ii++){
			// parte entera
			if((this.enteredValue.length - this.decimalPos) > t){
				if(this.enteredValue[t] !== this.decimalSeparator ){
					this.enteredValue.splice(t,1);
				}else{
					t++;
				}
			}else{ // en los decimales
				this.enteredValue[t++]="0";
			}
		}
		this.applyMask(this.enteredValue.join(''));

		setCaretPosition(this.control,selStart);
		return selStart;

	};
	numericInput.prototype.processCustomKeys = function(charCode){
		var selStart = getSelectionStart(this.control);
		var selEnd = getSelectionEnd(this.control);
		var newPos,c;
		if(selStart===null){
			return false;
		}

		if(selStart === selEnd){
			if(charCode === 8){ // backspace
				// guarda la cantidad de separadores de miles que tiene el numero

				if(selStart === null || selStart===0){
					return false;
				}
				c = this.thousandsChars;

				newPos = selStart-1;
				if(this.enteredValue[newPos]!==this.decimalSeparator){
					if(this.enteredValue.length == 1 && this.decimalPos == 0){
						this.enteredValue[0] = "0";
					}else{
						if((this.enteredValue.length - this.decimalPos) > newPos){
							this.enteredValue.splice(newPos,1);
						}else{
							this.enteredValue[newPos]="0";
						}
					}
				}else{
					newPos--;
					this.enteredValue.splice(newPos,1);
				}
				this.applyMask(this.enteredValue.join(''));
				// ajusta la posicion del cursor para mantenerlo fijo si desaparece un separador de miles
				newPos-=(c-this.thousandsChars);
				setCaretPosition(this.control,newPos );
				return false;
			}

			if(charCode === 46){ // delete
				// guarda la cantidad de separadores de miles que tiene el numero
				c = this.thousandsChars;
				newPos = selStart;

				if ((this.enteredValue[newPos] !== this.decimalSeparator) && (this.enteredValue[newPos] !== '-')) {
					if (this.enteredValue[newPos] === this.thousandsSeparator) {
						newPos++;
					}
					if (this.enteredValue.length == 1 && this.decimalPos == 0) {
						this.enteredValue[0] = "0";
					} else {
						if ((this.enteredValue.length - this.decimalPos) > newPos) {

							this.enteredValue.splice(newPos, 1);
							if (this.enteredValue[newPos] === this.decimalSeparator && (newPos === 0 || newPos === (this.negative ? 1 : 0))) {
								newPos += 2;
							}
						} else {
							this.enteredValue[newPos] = "0";
							newPos++;
						}
					}
				} else {
					// sobre el separador avanzamos una pocison y borramos

					newPos++;
					this.enteredValue.splice(newPos,1);
				}

				this.applyMask(this.enteredValue.join(''));
				newPos-=(c-this.thousandsChars);
				if(newPos===0 && this.enteredValue[0]==='-'){
					newPos++;
				}
				if(this.value===0){
					newPos=0;
				}
				setCaretPosition(this.control,newPos );
				return false;
			}
		}else{
			if(charCode === 8 || charCode===46){ // backspace con multiple seleccion
				this.clearMultiSelection(selStart,selEnd);
				return false;

			}


		}

		return true;
	};
	numericInput.prototype.setReadOnly = function(value){
		this.readOnly = value;
	};
	numericInput.prototype.isEmpty = function(){
		if(trim(this.getValue()) === ''){
			return true;
		}
		return false;

	};



	var dummyMask;
	dummyMask = function(node,opts,onchange,onblur){
		var self = this;
		this.control = node;
		this.changecb = onchange;
		this.blurcb = onblur;
		var tstval = 20;
		if(opts.maxLength){
            node.setAttribute("maxLength",opts.maxLength);
        }

		prisma.setEvent(this.control,"keydown",function(e){
		    if(self.tst){
    			e.preventDefault();
		        return false;
		    }
            self.tst = setTimeout(function(){
                self.tst = null;
            },tstval);

			if(self.readOnly){
				return false;
			}
			self.fireOnChange();
			return true;
		});
		prisma.setEvent(this.control,"blur",function(e){
			if(self.readOnly){
				e.preventDefault();
    		    return false;
			}
			var newval = self.control.value;

			if(self.fire_on_change_timeout){
                clearTimeout(self.fire_on_change_timeout);
                self.fire_on_change_timeout = null;

			}
			if(self.prevValue !== newval){
                self.prevValue = newval;
                self.changecb(newval);
			}
			setTimeout(
			  function(){
				self.blurcb();
			  },200);
			return true;
		});
	};

	dummyMask.prototype.applyMask = function (value){
		if(this.control.value !== value && value !== this.prevValue)
			this.control.value = value;
	  this.prevValue = value;

	};
	dummyMask.prototype.setValue = function (value){
		if(value === null)
		  value = "";
		this.applyMask(value);
	};
	dummyMask.prototype.fireOnChange = function(){
		var self = this;
		if(this.fire_on_change_timeout){
			clearTimeout(this.fire_on_change_timeout);
			this.fire_on_change_timeout = null;
		}
		this.fire_on_change_timeout = setTimeout(function(){
			self.fire_on_change_timeout = null;
			var newval = self.control.value;
			if(self.prevValue !== newval){
				self.prevValue = newval;
				self.changecb(newval);

			}
		},500);
	};

	if (typeof prisma !== 'object') {
		prisma = {};
	}

	prisma.textinput_mask = function (node,mask,opts,onchange,onblur){
		var d;
		if (mask && mask !== "password"){
			if(mask == "numeric"){
				d = new numericInput(node,opts.maxLength,opts.decimals,opts.currency,opts.allowNegative,opts.decimalSeparator,opts.thousandsSeparator,onchange,onblur);
			}else{

				d = new maskInput(node,mask,onchange,onblur);
			}

		}else{
                   if (mask == "password") {
                     node.type = "password";
                   }
                   d = new dummyMask(node,opts,onchange,onblur);
		}
		return function (value){
			return d.setValue(value);
		};
	};
})();
prisma_textinput_mask = true;
