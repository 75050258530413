(function() {

    function QueuedView (def,funnel) {
      QueuedView.SUPERconstructor.call(this, def, funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/virtual-queue"), QueuedView, prisma.Page);

    QueuedView.prototype.createContent = function(definition) {
        var self = this;
        this.definition = definition;
        var body = QueuedView.SUPERclass.createContent.call(this, definition[":layout"])
        var funnelSource = definition[":funnel-source"];

        var funnel = this.funnel;
        setTimeout(function() {
                   funnel.nextFunnelStep(function (status) {
                     if (status) {
                         funnel.currentPosition = funnel.currentPosition + 1;
                         funnel.loadCurrentView();
                     }
                   }, undefined, "", funnelSource);
        }, (definition[":refresh-ms"] || 10000));
        return body;
    };

    prisma_plugins = true;
})();
