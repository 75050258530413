(function() {
    function sendRequest(url,onSuccess,onError,postData) {
        var req = createXMLHTTPObject();
        if (!req) return;
        var method = (postData) ? "POST" : "GET";
        req.open(method,url,true);
        req.setRequestHeader("Prisma-Version", (prisma.version || ""));
        req.timeout = 30000; // I would not be encouraged to exceed 30 seconds
                             // due to that old Chrome error with https

        req.onload = function (){
            if (req.readyState == 4) {
                // TODO others 2XX and 3XX aren't handled well
                    if (req.status != 200 && req.status != 304 && req.status != 0) {
                        console.log("xmlhttp_request error " + req.statusText + " status " + req.status);
                        onError({data: {status: req.status}});
                    } else {
                        onSuccess(req.responseText);
                    }
                }
        }
        req.ontimeout = function (e){
            onError({status: "timeout"});
        }
        req.onerror = function (e){
            onError({data: {status: req.status}});
        }
        if (req.readyState == 4) return;
        req.send(postData);
    }

    var XMLHttpFactories = [
        function () {return new XMLHttpRequest();},
        function () {return new ActiveXObject("Msxml2.XMLHTTP");},
        function () {return new ActiveXObject("Msxml3.XMLHTTP");},
        function () {return new ActiveXObject("Microsoft.XMLHTTP");}
    ];

    function createXMLHTTPObject() {
        var xmlhttp = false;
        for (var i=0;i<XMLHttpFactories.length;i++) {
            try {
                xmlhttp = XMLHttpFactories[i]();
            }
            catch (e) {
                continue;
            }
            break;
        }
        return xmlhttp;
    }

    function api(server, port, appToken, protocol) {
        this.server = server;
        this.port = port || "8080";
        this.appToken = appToken;
        this.protocol = protocol || "http:";

    }
    api.prototype.getUrl = function() {
        return prisma.format("{0}//{1}:{2}/",this.protocol, this.server, this.port);
    };

    api.prototype.getBaseUrl = function() {
        return prisma.format("{0}//{1}:{2}",this.protocol, this.server, this.port);
    };

    api.prototype.post = function(url, data, onSuccess, onError) {
        var self = this;
        url = this.getBaseUrl()+url;
        sendRequest(url,onSuccess,onError,jsedn.encode(data));
    };

    api.prototype.get = function(url, parameters, onSuccess, onError) {
        var self = this;
        var queryString = [];
        for (var name in parameters) {
            if (parameters.hasOwnProperty(name)) {
                queryString.push(name + "=" + parameters[name]);
            }
        }
        var q = queryString.join("&");

        var url = q ? url + (~url.indexOf('?') ? '&' : '?') + q : url;
        sendRequest(url,onSuccess,onError);
    };

    if (typeof prisma !== 'object') {
        prisma = {};
    }
    prisma_api = api;
    prisma.api = api;
    prisma_xmlhttp_api = api;

})();
