
(function(){
    function MessageView (def,funnel) {
        MessageView.SUPERconstructor.call(this,def,funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/message"), MessageView);

    MessageView.prototype.createContent = function (definition) {
        var body = document.createElement("div");
        var title = prisma.linkify((definition[":title"] || ""));
        var message = definition[":message"];
        var message_display_div = document.createElement("div");
        message_display_div.className = "message-display";

        var title_node = document.createElement("h1");
        title_node.className = "prisma-title-component";
        title_node.appendChild(document.createTextNode(title || ""));

        var text_node = document.createElement("div");
        text_node.innerHTML = (message || "");
        text_node.className = "prisma-text-component";
        message_display_div.appendChild(title_node);
        message_display_div.appendChild(text_node);

        if (definition[":captcha-v2"]) {
            var context = {
                view: this
            };
            var row = document.createElement("div");
            this.captcha = new prisma.recaptcha_v2(context, {
                context_registration: false,
                "google-captcha-v2-site-key": definition[":google-captcha-v2-site-key"],
                form_alignment: "vertical"
            }, []);
            row.appendChild(this.captcha.create());
            message_display_div.appendChild(row);
        }
        body.appendChild(message_display_div);
        return body;
    };
    MessageView.prototype.retrieveData = function (cb) {

        if (this.captcha)
            this.captcha.retrieveData(function (status,response) {
                cb(status, {
                    "captcha-response": response
                })
            })
        else
            cb(true, {});
    }

    prisma_plugins = true;
})();
