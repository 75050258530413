(function () {
		var tonumber = function (v){
			var r = Number(''+v);
			return !isNaN(r)? r :null;
		}

		var evaluate = function (parseTree, done, error, resolvefn) {
			var typeCoercion = function (a, b) {
				if (typeof a == "number" || !isNaN(Number('' + a))) {
					if (b == null || b == undefined || b == '') {
						return [Number('' + a), 0];
					} else if (typeof b == "number") {
						return [Number('' + a), Number('' + b)];
					} else if (typeof b == "string") {
						if (!isNaN(Number(b))) {
							return [Number('' + a), Number(b)];
						}
					}

				} else if (typeof a == "boolean" || ('' + a).toLowerCase() == "false" || ('' + a).toLowerCase() == "true") {
					if (b == null || b == undefined || b == '') {
						return [('' + a).toLowerCase() == "true", false];
					} else if (typeof b == "boolean") {
						return [('' + a).toLowerCase() == "true", b];
					} else if (typeof b == "string") {
						if (b.toLowerCase() == "false") {
							return [('' + a).toLowerCase() == "true", false];
						} else if (b.toLowerCase() == "true") {
							return [('' + a).toLowerCase() == "true", true];
						}
					}
				} else if (a instanceof Date || prisma.stringToDate('' + a)) {
					if (b == null || b == undefined || b == '') {
						return [((a instanceof Date) ? a : prisma.stringToDate('' + a)).getTime(), new Date(0, 0, 0).getTime()]
					} else if (prisma.stringToDate('' + b)) {
						return [((a instanceof Date) ? a : prisma.stringToDate('' + a)).getTime(), prisma.stringToDate('' + b).getTime()]
					}
				}
			}


			var coerceOperands = function (a, b, lcase) {
				var r = typeCoercion(a, b);
				if (!r) {
					r = typeCoercion(b, a);
					if (r)
						return [r[1], r[0]];
					else {
					    if (lcase)
						    return [('' + a).toLowerCase(), ('' + b).toLowerCase()];
					    else
					        return [('' + a), ('' + b)];
					}
				} else {
					return r;
				}

			}

			var asyncwrapper = function (pfn) {
				return function (done) {
					var args = []
					for (var i = 1; i < arguments.length; i++) {
						args.push(arguments[i])
					}
					done(pfn.apply(null, args));
				}
			}
			var globals = {
				"true": true,
				"false": false,
				abs: asyncwrapper(Math.abs),
				round: asyncwrapper(Math.round),
				ceil: asyncwrapper(Math.ceil),
				floor: asyncwrapper(Math.floor),
				max: asyncwrapper(Math.max),
				min: asyncwrapper(Math.min),
				mod: asyncwrapper(function(v,b){
					return v % b;
				}), 
				random: asyncwrapper(Math.random),
				loginfo: asyncwrapper(console.log),
				val: asyncwrapper(function (v){
					return Number(''+v);
				}),
				substring:asyncwrapper(function(v,f,t){
					v = ''+v;
					return v.substring(f,t)
				}),
				trim: asyncwrapper(function (v){
					return (''+v).trim();
				}),
				replace: asyncwrapper(function(value,regexp,s){
					return (''+value).replace(regexp,s);
				}),
				match: asyncwrapper(function(value,regexp){
					return (''+value).match(regexp);
				}),
				regexp: asyncwrapper(function (s,opts){
					return new RegExp (s,opts || "g");
				}),
				
				object: asyncwrapper(function (){
					return {}
				}),
				dict: asyncwrapper(function (){
    				return {}
   				}),
				array:  asyncwrapper(function (){
					return []
				}),
				alen: asyncwrapper(function (a){
					if (Array.isArray(a))
						return a.length;
					else
						return 0;
				}),
				aget: asyncwrapper(function (a,index){
					if(Array.isArray(a)){
						return a[index]
					}
				}), 
				
				aset: asyncwrapper(function (a,index,v){
					if(Array.isArray(a)){
						a[index]=v;
					}
				}), 

				oset: asyncwrapper(function (o,k,v){
					o[k]=v;
					return o;
				}),
				oget: asyncwrapper(function (o,k){
					return o[k];
				}),
				set: asyncwrapper(function (o,k,v){
					o[k]=v;
					return o;
				}),
				get: asyncwrapper(function (o,k){
					return o[k];
				}),

				len: asyncwrapper(function (s){
					return (''+s).length;
				}),
				not: asyncwrapper(function (v) {
					return !v;
				}),
				split: asyncwrapper(function (v,s){
				     return v.split(s);
				})
			};

			var comparators = {
				'==': function (a, b, done, error) {
					var ops = coerceOperands(a, b, false);
					done(ops[0] == ops[1]);
				},
				'!=': function (a, b, done, error) {
					var ops = coerceOperands(a, b, false);
					done(ops[0] != ops[1]);
				},
				'>': function (a, b, done, error) {
					var ops = coerceOperands(a, b, true);
					done(ops[0] > ops[1]);
				},
				'>=': function (a, b, done, error) {
					var ops = coerceOperands(a, b, true);
					done(ops[0] >= ops[1]);
				},
				'<': function (a, b, done, error) {
					var ops = coerceOperands(a, b, true);
					done(ops[0] < ops[1]);
				},
				'<=': function (a, b, done, error) {
					var ops = coerceOperands(a, b, true);
					done(ops[0] <= ops[1]);
				}
			}
			var g_getter = function (ident, cb) {
				if (globals[ident] === undefined) {
					resolvefn(ident, cb);
				} else {
					cb(globals[ident])
				}
			}
			var g_setter = function (ident, value) {
				globals[ident] = value;
			}
			
			var parseNode = function (ptree, getter, setter, done, error) {
				var output;
				if (ptree) {
					switch (ptree[0]) {
						case ":prg":
							var p = ptree.slice();
							p.shift();
							var pnext = function (r) {
								var n = p.shift();
								if (n) {
									parseNode(n, getter, setter, function (value, brk) {
										if (brk) {
											done(value,brk)
										} else {
											pnext(value)
										}
									}, error)
								} else {
									done(r);
								}
							}
							pnext(null);
							break;
						case ":scope":
							var p = ptree.slice();
							var local_scope = {}
							var local_getter = function (ident, cb) {
								getter(ident, function (value) {
									if (value == undefined) {
										if (local_scope[ident] === undefined) {
											g_getter(ident, cb)
										} else
											cb(local_scope[ident]);
									} else {
										cb(value)
									}
								})
							}
							p.shift();
							var pnext = function (r) {
								var n = p.shift();
								if (n) {
									parseNode(n, local_getter, setter, function (value, brk) {
										if (brk) {
											done(value)
										} else {
											pnext(value)
										}
									}, error)
								} else {
									done(r);
								}
							}
							pnext(null);
							break;

						case ":cond":
						case ":condr":
							if (ptree[2] && (ptree[2][0] == ":or" || ptree[2][0] == ":and")) {
								parseNode(ptree[1], getter, setter, function (left) {
									parseNode(ptree[3], getter, setter, function (right) {
										if (ptree[2][0] == ":or")
											done(left || right);
										else
											done(left && right)
									}, error)
								}, error);
							} else
								parseNode(ptree[1], getter, setter, done, error);
							break;
						case ":comparation":
							parseNode(ptree[1], getter, setter, function (left) {
								parseNode(ptree[3], getter, setter, function (right) {
									comparators[ptree[2][1]](left, right, done);
								}, error)
							}, error);
							break;

						case ":number":
							done(parseFloat(ptree[1]));
							break;
						case ":string":
							var r="";
							for (i = 1;i<ptree.length;i++){
							    if(ptree[i] == "\\\" ")
							        r+="\" "
							    else
								if (ptree[i]=="\\\"")
									r+="\""
								else
									r+=ptree[i];
							}
							done(r);
							break;
						case ":ident":
							getter(ptree[1], done);
							break;
						case ":additive-expr":
							if ((ptree[2] == "+") ||
								(ptree[2] == "&")) {
								parseNode(ptree[1], getter, setter, function (r1) {
									parseNode(ptree[3], getter, setter, function (r2) {

										if (ptree[2] == "&") {
											done('' + ('' + r1) + ('' + r2));
										} else {
											var rn1 = tonumber(r1)
											var rn2 = tonumber(r2);
											if (rn1 !== null && rn2 !== null) {
												done(rn1 + rn2);
											} else {
												done(('' + r1) + ('' + r2));
											}
										}

									}, error)
								}, error)
							} else {
								parseNode(ptree[1], getter, setter, function (r1) {
									parseNode(ptree[3], getter, setter, function (r2) {
										var rn1 = tonumber(r1);
										var rn2 = tonumber(r2);
										if(rn1 !== null && rn2 !== null ) {
											done(rn1 - rn2)
										} else {
											error("Error substracting non-numerical values");
										}
									}, error)
								}, error)
							}
							break;
						case ":multiplicative-expr":
							if (ptree[2] == "*") {
								parseNode(ptree[1], getter, setter, function (r1) {
									parseNode(ptree[3], getter, setter, function (r2) {
										var rn1 = tonumber(r1);
										var rn2 = tonumber(r2);
										if(rn1 !== null && rn2 !== null ) {
											done(rn1 * rn2)
										} else {
											error("Error multiplying non-numerical values");
										}
									}, error)
								}, error)
							} else {
								parseNode(ptree[1], getter, setter, function (r1) {
									parseNode(ptree[3], getter, setter, function (r2) {
										var rn1 = tonumber(r1);
										var rn2 = tonumber(r2);
										if(rn1 !== null && rn2 !== null ) {
											done(rn1 / rn2)
										} else {
											error("Error dividing non-numerical values");
										}
									}, error)
								}, error)
							}
							break;

						case ":assign":
							parseNode(ptree[2], getter, setter, function (r) {
								setter([ptree[1][1]], r);
								done(r);
							}, error)
							break;

						case ":if-then-else":
							parseNode(ptree[1], getter, setter, function (r) {
								if (r) {
									parseNode(ptree[2], getter, setter, done, error)
								} else {
									parseNode(ptree[3], getter, setter, done, error)
								}
							}, error)
							break;
						case ":if-then":
							parseNode(ptree[1], getter, setter, function (r) {
								if (r) {
									parseNode(ptree[2], getter, setter, done, error)
								} else {
									done(null)
								}
							}, error)
							break;
						case ":toggle-field":
							getter("toggle-field", function (r) {
								if (r) {
									r(ptree[1][0],ptree[2][1],done)
								}
							});
							done(null);

						break;
						case ":field-on": 
							getter("field-on", function (r) {
								if (r) {
									r(ptree[1][1], ptree[2][1],
										function (done) {
											var args = {};
											args[ptree[3][1]] = arguments[1];
											parseNode(ptree[4], function (ident, cb) {
												if (args[ident] === undefined) {
													getter(ident, cb);
												} else {
													cb(args[ident])
												}
											}, function (ident, value) {
												if (args[ident] !== undefined) {
													args[ident] = value;
												} else {
													setter(ident, value);
												}
											}, done, function (err){
												done();
											});
										}
									)
								}
								done(null);
							})
						
						break;
						case ":get-field": 
							getter("get-field", function (r){
								if(r){
									r(ptree[1][1],done);
								}else
									done(null)
							})

						
						break;
						case ":set-field":
							if (ptree[1][0] == ":field") {
								getter("set-field", function (r) {
									if (r) {
										parseNode(ptree[3], getter, setter, function (value) {
											r(ptree[2][1], value)
											done(null);
										}, error)
									}
								})

							} else if (ptree[2][0] == ":from") {
								getter("set-when", function (r) {
									if (r) {
										r(ptree[1][1], "value" ,
											function (done) {
												parseNode(ptree[3], getter, setter, done, function(err){
													done()
												})
											},
											function (done) {
												done(true)
											});
									}
								})																

							} else if (ptree[3][0] == ":when") {
								getter("set-when", function (r) {
									if (r) {
										r(ptree[1][1], ptree[2][1] == "visible" ? "visibility" : ptree[2][1],
											function (done) {
												parseNode(ptree[4], getter, setter, done, function (err){
													done()
												});
											},
											function (done) {
												done(true)
											});
									}
								})
							}
							else if (ptree[4][0] == ":when") {
								getter("set-when", function (r) {
									if (r) {
										r(ptree[2][1], ptree[1][1],
											function (done) {
												parseNode(ptree[3], getter, setter, done, function(err){
													done();
												})
											},
											function (done) {
												parseNode(ptree[5], getter, setter, done, function(err){
													done();
												})
											});
									}
								})
							}
							done(null);
						break;
						case ":return":
							parseNode(ptree[1], getter, setter, function (r) {
								done(r, true)
							}, error);
							break;
						case ":defn":
							setter([ptree[1][1]], function (done,erhandler) {
								var st = 0;
								var err = error;
								var scpos = 3;
								if (erhandler && erhandler.fn){
									err = erhandler.fn
									st++;
								}
								var args = {};
								if(ptree[2][0] == ":fn-args"){
									for (var i = st; i < ptree[2].length; i++) {
										args[ptree[2][i][1]] = arguments[i+st];
									}

								}else
									scpos = 2;

								parseNode(ptree[scpos], function (ident, cb) {
									if (args[ident] === undefined) {
										getter(ident, cb);
									} else {
										cb(args[ident])
									}
								}, function (ident, value) {
									if (args[ident] !== undefined) {
										args[ident] = value;
									} else {
										setter(ident, value);
									}
								}, done, err)
							})
							getter(ptree[1][1], done)
							break;
						case ":lambda-fn":
							done(function (done) {
								var args = {};
								for (var i = 1; i < ptree[1].length; i++) {
									args[ptree[1][i][1]] = arguments[i];
								}
								parseNode(ptree[2], function (ident, cb) {
									if (args[ident] === undefined) {
										getter(ident, cb);
									} else {
										cb(args[ident])
									}
								}, function (ident, value) {
									if (args[ident] !== undefined) {
										args[ident] = value;
									} else {
										setter(ident, value);
									}
								}, done, error)
							})
							break;
						case ":fn-call":
							getter(ptree[1][1], function (r) {
								if (r && typeof r == "function") {
									var args = [done];
									var cargs = []
									if (ptree[2]) {
										cargs = ptree[2].slice();
										cargs.shift();
									}
									var next = function () {
										var n = cargs.shift();
										if (n) {
											parseNode(n, getter, setter, function (r) {
												args.push(r);
												next();
											}, error)
										} else {
											setTimeout(function () {
												try {
													r.apply(null, args)
												} catch (e) {
													error("Error interno en la funcion " + ptree[1][1]);
												}
											}, 0)
										}
									}
									next();

								} else {
									error("Attempt to call a not function " + ptree[1][1])
								}
							})
							break;
						case ":while-fn":
							var result;
							var executeBody = function (done) {
								parseNode(ptree[2], getter, setter, done, error)
							}
							var checkCond = function (done) {
								parseNode(ptree[1], getter, setter, done, error)
							}
							var whileloop = function (done) {
								checkCond(function (r) {
									if (r) {
										executeBody(function (r) {
											result = r;
											setTimeout(function () {
												whileloop(done)
											}, 0)
										})
									} else
										done(result);
								})
							}
							whileloop(done);
							break;
						default:
							done();

					}

				} else
					done();
			}
			return parseNode(parseTree, g_getter, g_setter, done, error);
		}
	prisma.script = prisma.script || {};
	prisma.script.evaluate = evaluate;
	prisma.clss = prisma.script;
})();
