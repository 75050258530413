(function() {

    function FormView(def,funnel) {
        FormView.SUPERconstructor.call(this,def,funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/data-capture"), FormView);

    FormView.prototype.handleFieldChange = function (field,value) {
    }
    FormView.prototype.createContent = function(definition) {
        var self = this;
        var viewForm = document.createElement("div");
        var fields = [];
        for (var i=0;i<definition[":fields"].length;i++){
            fields.push(prisma.unkeywordize(definition[":fields"][i]))
        }
        var api = self.funnel.handler.api;
        var context = {api: api,
                       view:self,
                       controls:[],
                       resolution:"small"}
        this.datacapture = new prisma.datacapture(context,{fields: fields,
                                                           "form-alignment": definition[":label-alignment"] == ":vertical" ? "vertical" : "horizontal-6-6",
                                                           "captcha-v2" : definition[":captcha-v2"],
                                                           "google-captcha-v2-site-key" : definition [":google-captcha-v2-site-key"]},[]);
        viewForm.appendChild(this.datacapture.create());
        this.datacapture.runScripts("startup-scripts", function (identifier,resolve) {resolve();},function(){});
        return viewForm;
    };
    FormView.prototype.fireFieldValidateEvents = function (fieldId, resolve, reject, value) {
            resolve();
    }
    FormView.prototype.retrieveData = function(cb){
        this.datacapture.retrieveData(cb);
    };
    FormView.prototype.handleStepErrors = function (errors) {
        FormView.SUPERclass.handleStepErrors.call(this,errors);
        this.datacapture.handleErrors(errors);
    }

})();
