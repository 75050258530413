
(function(){
    function DocusignView (def,funnel) {
        DocusignView.SUPERconstructor.call(this,def,funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/docusign"), DocusignView);

    DocusignView.prototype.wasRedirected = function () {
        return this.redirected;
    };

    DocusignView.prototype.createContent = function(definition) {
        var error = definition[":docusign-error"]
        if (error) {
          var body = document.createElement("div");
          var message_display_div = document.createElement("div");
          message_display_div.className = "message-display";

          var title_node = document.createElement("h1");
          title_node.className = "prisma-title-component";
          title_node.appendChild(document.createTextNode("Error"));

          var text_node = document.createElement("div");
          text_node.innerHTML = definition[":error-message"]
          text_node.className = "prisma-text-component";
          message_display_div.appendChild(title_node);
          message_display_div.appendChild(text_node);

          body.appendChild(message_display_div);
          return body;
        }

        var url = definition[":docusign-url"];

        var onRedirect = this.funnel.handler.onRedirect;
        if (onRedirect) {
            onRedirect(url);
        } else {
          if (typeof(this.funnel.sandboxHash) != "undefined") {
            window.location.href = url;
          }
          else {
            window.top.location.href = url;
          }
        }

        this.redirected = true;
        this.redirectedUrl = url;
    };

    prisma_plugins = true;
})();
