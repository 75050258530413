(function () {

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    function Multivalue(context, opts, childs) {
        Multivalue.SUPERconstructor.call(this, context, opts, childs);
        this["fields-data"] = {}
        this.field = this["field-definition"]
        this.field["field-name"] = this.field["id"]
        this.field["name"] = this.field.id.match(new RegExp ("/[^/]+$"))[0].substr(1);
        this.field = this["field-definition"];
    }

    prisma.inherit(Multivalue, prisma.widget);


    Multivalue.prototype.handleFieldChange = function (field,value) {
    };

    Multivalue.prototype.cleanView = function () {
        this.viewContainer.innerHTML = "";
    };

    Multivalue.prototype.getItemText = function (item) {
        var self = this;
        var keys = [];
        for (var key in item) {
            if (item.hasOwnProperty(key) && key !== "prisma-id") {
                if (self["shown-fields"]) {
                    if (self["shown-fields"].findIndex(function (i) { return i === key; }) !== -1) {
                        keys.push(item[key])
                    }
                } else {
                    keys.push(item[key]);
                }
            }
        }
        return keys.reduce(function (text, value, i, array) {
            if (text !== '')
                return text + " - " + value;
            else return value;
        }, "");
    };

    Multivalue.prototype.buildComboOptions = function (items) {
        var self = this
        var options = [];
        for (var i = 0; i < items.length; i++) {
            options.push({text: self.getItemText(items[i]), value: items[i]['prisma-id']});
        }
        return options;
    };

    Multivalue.prototype.createDatacapture = function(item) {
        var self = this;
        var form = document.createElement("div");
        var fields = [];
        for (var i=0; i<self["fields-schema"].length; i++){
            var field = prisma.unkeywordize(self["fields-schema"][i]);
            if (item) {
                field.value = item[field.name];
            }
            field["field-name"] = self["field-definition"].id + ":" + field.name; 
            field["ns:name"] =  self["field-definition"].id.match(new RegExp ("/[^/]+$","gi"))[0].substr(1) + ":" + field.name;
            fields.push(field);
        }

        var context = {api: self.context.api,
            view: self.context.view,
            controls:[],
            resolution:"small"};

        var options = {fields: fields,
            context_registration: false,
            "form-alignment": self["form-alignment"] === "vertical" ? "vertical" : "horizontal-6-6",
            "google-captcha-v2-site-key" : self [":google-captcha-v2-site-key"]
        };

        self.currentDatacapture = new prisma.datacapture(context,options,[]);
        self.inputs = self.currentDatacapture.inputs;
        form.appendChild(self.currentDatacapture.create());
        form.className= "prisma-multivalue-container";
        return form;
    };

    Multivalue.prototype.createButton = function (text, className, icon, align, onClick, ariaLabel) {
        var self = this;
        var btn = new prisma.button({
            api: self.context.api,
            view: self.context.view,
            controls: [],
            resolution: "small"}, {
            text: "",
            enabled: true,
            action: ":none",
            icon: icon,
            align: align,
            ariaLabel: ariaLabel,
            className: className
        }, []);

        btn.onClick(onClick);

        return btn;
    };


    Multivalue.prototype.createDeleteButton = function (onClick) {
        return this.createButton("", "btn-link btn-icon", "public-icn-delete", "right", onClick, "Delete");
    };

    Multivalue.prototype.createCancelButton = function (onClick) {
        return this.createButton("", "btn-link btn-icon", "public-icn-cancel","right", onClick, "Cancel");
    };

    Multivalue.prototype.createOKButton = function (onClick) {
        return this.createButton("", "btn-link btn-icon", "public-icn-ok", "right", onClick, "Confirm");
    };

    Multivalue.prototype.createEditButton = function (onClick) {
        return this.createButton("", "btn-link btn-icon", "public-icn-edit", "right", onClick, "Edit");
    };

    Multivalue.prototype.createAddButton = function (onClick) {
        return this.createButton("",
            (this['add-item-style'] === "link" ? "btn btn-link" : "btn btn-default"),//btn-link btn-icon
            "public-icn-add",
            "left",
            onClick,
            "Add new");
    };

    Multivalue.prototype.showError = function (error) {
        this.errorsContainer.innerHTML = "";
        prisma.addClass(this.errorsContainer, "has-error");
        prisma.addClass(this.errorsContainer, "fixed");
        var errorNode = document.createElement("div");
        errorNode.innerHTML = "<span class='arrow'></span><p>" + error + "</p>";
        errorNode.className = "error align-top";
        this.errorsContainer.appendChild(errorNode);
    };

    Multivalue.prototype.hideError = function (error) {
        this.errorsContainer.innerHTML = "";
    };


    Multivalue.prototype.createInlineView = function() {
    };


    Multivalue.prototype.createButtonsToolbar = function(buttons) {
        var actionsDiv = document.createElement("div");
        actionsDiv.className = "prisma-multivalue-actions text-right";
        buttons.forEach(function (button) {
            actionsDiv.appendChild(button);
        });
        return actionsDiv;
    };


    Multivalue.prototype.createSelectorView = function() {
        var self = this;
        self.buttons = {};

        var options = self.buildComboOptions(self.items);
        var container = document.createElement("div");
        var containerHeader = document.createElement("div");
        containerHeader.className = "prisma-datacapture-component prisma-multivalue-header";

        self.combobox = new prisma.combobox({}, {
            id: self.id,
            storage_key: "",
            context_registration: false,
            value: "",
            label: "Multivalue",
            options: options,
            required: false,
            form_alignment: "vertical"
        }, []);
        containerHeader.appendChild(self.combobox.create());
        container.appendChild(containerHeader);

        var callbacks = {
            onItemEdited: function (){
                self.currentDatacapture.retrieveData(function (status, result) {
                    if (status) {
                        var item = jsedn.toJS(result);
                        if (self.currentId){
                            item['prisma-id'] = self.currentId;
                            var idx = self.items.findIndex(function(itemFound){
                                return itemFound['prisma-id'] === item['prisma-id'];
                            });
                            self.items.splice(idx,1,item);
                        } else {
                            item['prisma-id'] = uuidv4();
                            self.items.push(item);
                        }
                        self.context.view.handleFieldChange(self["field-definition"],self.items)
                        self.combobox.updateOptions(self.buildComboOptions(self.items));
                        self.combobox.setEnabled(true, true);
                        self.addButton.setEnabled(true);
                        self.currentId = null;
                        self.cleanView();
                    }
                })},

            onItemCancelled: function (){
                self.cleanView();
                self.currentId = null;
                self.addButton.setEnabled(true);
                self.combobox.updateOptions(self.buildComboOptions(self.items));
            },

            onItemDeleted: function (){
                // TODO confirmation?
                var newItems = self.items.filter(function(item){
                    return item['prisma-id'] !== self.currentId;
                });
                self.items = newItems;
                self.context.view.handleFieldChange(self["field-definition"],self.items)
                self.combobox.updateOptions(self.buildComboOptions(self.items));

                if (self.items.length === 0){
                    self.combobox.setEnabled(false, true);
                }

                self.cleanView();
                self.currentId = null;
                self.addButton.setEnabled(true);
            },

            onEdit: function(){
                self.currentDatacapture.setEnabled(true);
                self.buttons.delete.setVisible(false, false);
                self.buttons.edit.setVisible(false, false);
                self.buttons.cancel.setVisible(true, false);
                self.buttons.ok.setVisible(true, false);
                self.addButton.setEnabled(false);
            },

            onItemAdded: function(){
                self.hideError();
                if (self["maximum-items"] && self.items.length === self["maximum-items"]) {
                    self.showError(this._T(":funnel-navigation",":errors-maximum-reached"));
                } else {

                    if (self.currentId){
                        self.cleanView();
                        self.combobox.updateOptions(self.buildComboOptions(self.items));
                    }

                    self.formContainer = self.createDatacapture();
                    var buttons = [self.createCancelButton(callbacks.onItemCancelled).create(),
                                   self.createOKButton(callbacks.onItemEdited).create()];
                    self.formContainer.appendChild(self.createButtonsToolbar(buttons));
                    self.viewContainer.appendChild(self.formContainer);
                    self.addButton.setEnabled(false);
                }
            },

            onItemSelected: function(itemId){
                self.cleanView();
                self.hideError();
                var item = self.items.find(function(item){
                    return item['prisma-id'] === itemId;
                });
                self.formContainer = self.createDatacapture(item);
                self.currentDatacapture.setEnabled(false);

                self.buttons.delete = self.createDeleteButton(callbacks.onItemDeleted);
                self.buttons.edit = self.createEditButton(callbacks.onEdit);
                self.buttons.cancel = self.createCancelButton(callbacks.onItemCancelled);
                self.buttons.ok = self.createOKButton(callbacks.onItemEdited);

                var buttons = [self.buttons.delete.create(),
                               self.buttons.cancel.create(),
                               self.buttons.ok.create(),
                               self.buttons.edit.create()];
                self.buttons.ok.setVisible(false);
                self.buttons.cancel.setVisible(false);
                self.formContainer.appendChild(self.createButtonsToolbar(buttons));
                self.viewContainer.appendChild(self.formContainer);
                self.currentId = itemId;
            }
        };

        self.addButton = self.createAddButton(callbacks.onItemAdded);
        self.viewContainer = document.createElement("div");
        self.errorsContainer = document.createElement("div");

        container.appendChild(self.viewContainer);
        container.appendChild(self.errorsContainer);
        containerHeader.appendChild(self.addButton.create());

        self.combobox.onChange(callbacks.onItemSelected);

        return container;
    };

    Multivalue.prototype.createTableView = function() {
    };

    Multivalue.prototype.retrieveData = function (cb) {
        var self = this;
        var response = new jsedn.Map();

        if (self["minimum-items"] && self.items.length < self["minimum-items"]) {
            self.showError(this._T(":funnel-navigation", ":errors-minimum-required"));
            cb(false, response);
        }
        self.validate(self.items,
            function () {
                var items = [];
                for (var i = 0; i < self.items.length; i++) {
                    var item = self.items[i];
                    var itemMap = new jsedn.Map();

                    for (var key in item) {
                        if (item.hasOwnProperty(key) && key !== "prisma-id") {
                            itemMap.set(key, item[key]);
                        }
                    }
                    items.push(itemMap);
                }
                response.set(new jsedn.kw(":items"), new jsedn.Vector(items));
                cb(true, response);
            },
            function (err) {
                self.showError(err)
                cb(false)
            });
    };

    Multivalue.prototype.create = function () {
        var self = this;

        self.editionMode = self['edit-mode'] || "selector";
        self.items = self.items || [];

        self.container = document.createElement("div");
        self.container.className = "prisma-multivalue-component";
        self.container.id = self.id;

        var contentStyle = (this.padding ? "padding: " + this.padding + ";" : "padding: 10px;");
        self.container.setAttribute("style", contentStyle);

        if (self.editionMode === "inline") {
            self.container.appendChild(self.createInlineView());
        } else if (self.editionMode === "selector") {
            self.container.appendChild(self.createSelectorView());
        }
        // TODO
        else if (self.editionMode === "table") {
            self.container.appendChild(self.createTableView());
        }
        self.context.view.handleFieldChange(self["field-definition"],self.items)
        
        return self.container;
    };

    prisma.multivalue = Multivalue;
})();
