(function() {

    function createHeaders (body, definition){
        var message_display_div = document.createElement("div");
        message_display_div.className = "message-display";

        var title_node = document.createElement("h1");
        title_node.className = "prisma-title-component";

        var text_node = document.createElement("div");
        text_node.className = "prisma-text-component";

        var title = prisma.linkify((definition[":title"] || ""));
        var message = definition[":message"];
        title_node.appendChild(document.createTextNode(title || ""));

        text_node.innerHTML = (message || "");
        message_display_div.appendChild(title_node);
        message_display_div.appendChild(text_node);
        body.appendChild(message_display_div);
    }

    function createDatacapture (formView, fields, definition){
        var api = formView.funnel.handler.api;
        var context = {api: api,
            view:formView,
            controls:[],
            resolution:"small"};
        var datacaptureOpts = {fields: fields,
            "form-alignment": definition[":label-alignment"] == ":vertical" ? "vertical" : "horizontal-6-6",
        };
        formView.datacapture = new prisma.datacapture(context,datacaptureOpts,[]);
        return formView.datacapture.create();
    }

    function createField (formView, question){
        return {"control-type": ":combobox",
                "campaign": formView.funnel.campaignId,
                "category": ":campaign-data",
                "component-type": ":field",
                "db-type": ":db.type/string",
                "link-field": ":new",
                "required": question[":required"],
                "label": question[":question"],
                "name": question[":id"],
                "options": question[":options"]
        };
    }

    function mergeOpts (oldOpts, newOpts) {
        var opts = newOpts.jsEncode();
        for (var key in opts) {
            if (opts.hasOwnProperty(key)) {
                oldOpts[key] = opts[key];
            }
        }
    }

    function createNextButton (formView, definition){

        var actionsDiv = document.createElement("div");
        actionsDiv.id = "prisma_actions";
        actionsDiv.className = "prisma-actions-component";
        var actionsContent = document.createElement("div");
        actionsContent.className="prisma-actions-component-content";

        var buttonContext = {api: formView.funnel.handler.api,
                             view:formView,
                             controls:[],
                             resolution:"small"};
        var lastStep = definition[":questions"].length - 1;

        var advanceWizard = function (){
            formView.datacapture.retrieveData(function(valid, optsEdn){
                if (valid) {
                    mergeOpts (formView.currentOpts, optsEdn);
                    if (formView.oneQuestionPerPage && lastStep != formView.currentStep){
                        formView.currentStep = formView.currentStep + 1;

                        formView.node.innerHTML = "";
                        var nextQuestion = definition[":questions"][formView.currentStep];
                        var newFields = [];
                        newFields.push(createField(formView, nextQuestion));

                        var nextButton = createNextButton(formView, definition);
                        var viewForm = document.createElement("form");
                        viewForm.appendChild(createDatacapture(formView, newFields, definition));
                        formView.node.appendChild(viewForm);
                        formView.node.appendChild(nextButton);
                    }

                }
            });
        };

        var wizzardEnabled = formView.oneQuestionPerPage && lastStep != formView.currentStep;
        var btn = new prisma.button(buttonContext, {
            text: formView._T(":funnel-navigation", ":next-button"),
            enabled: true,
            action: wizzardEnabled ? ":none" : ":funnel-step-s12n-action-type/next",
            align: "right",
            className: "btn btn-primary"
        }, []);

        if (wizzardEnabled) {
            btn.onClick(function (event){
                advanceWizard();
            });
        }

        actionsContent.appendChild(btn.create());
        actionsDiv.appendChild(actionsContent);
        return actionsDiv;
    }

    function createErrorContent (formView, definition){
        var body = document.createElement("div");

        var message_display_div = document.createElement("div");
        message_display_div.className = "message-display";

        var title_node = document.createElement("h1");
        title_node.className = "prisma-title-component";

        var text_node = document.createElement("div");
        text_node.className = "prisma-text-component";

        var error = definition[":questions-error"];
        if (error) {
            formView.errorType = definition[":error-type"];
            switch (this.errorType) {
                case ':incomplete':
                    title_node.appendChild(document.createTextNode("Error"));
                    // TODO translations
                    text_node.innerHTML = "This funnel was abandoned and it's no longer valid.";
                    break;
                default:
                    title_node.appendChild(document.createTextNode("Error"));
                    text_node.innerHTML = definition[":error-message"];
                    break;
            }
            message_display_div.appendChild(title_node);
            message_display_div.appendChild(text_node);
            body.appendChild(message_display_div);

            var buttonContext = {api: formView.funnel.handler.api,
                                 view:formView,
                                 controls:[],
                                 resolution:"small"};
            var btn = new prisma.button(buttonContext, {
                text: formView._T(":funnel-navigation", ":next-button"),
                enabled: true,
                action: ":funnel-step-s12n-action-type/next",
                align: "right",
                className: "btn btn-primary"
            }, []);

            body.appendChild(btn.create());

            return body;
        }
    }

    function FormView(def,funnel) {
        FormView.SUPERconstructor.call(this,def,funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/questions"), FormView);

    FormView.prototype.handleFieldChange = function (field,value) {
    };

    FormView.prototype.fireFieldValidateEvents = function (fieldId, resolve, reject, value) {
      resolve();
    }

    FormView.prototype.createContent = function(definition) {
        var self = this;
        self.currentOpts = {};

        var error = definition[":questions-error"];
        if (error) {
            return createErrorContent(self, definition);
        }

        self.oneQuestionPerPage = definition[":one-question-per-page"] || false;
        self.contextFields = definition[":context-fields"] || false;
        self.currentStep = self.currentStep ? self.currentStep : 0;

        var body = document.createElement("div");
        var viewForm = document.createElement("form");

        var fields = [];
        if (self.oneQuestionPerPage) {
            var question = definition[":questions"][self.currentStep];
            fields.push(createField(self, question));
        } else {
            createHeaders(body, definition);
            for (var i=0;i<definition[":questions"].length;i++){
                var question = definition[":questions"][i];
                fields.push(createField(self, question));
            }
        }
        viewForm.appendChild(createDatacapture(self, fields, definition));
        var nextButton = createNextButton(self, definition);
        body.appendChild(viewForm);
        body.appendChild(nextButton);
        self.node = body;
        return body;
    };

    FormView.prototype.retrieveData = function(cb){
        var self = this;
        if (this.errorType){
            var r = new jsedn.Map();
            r.set(jsedn.kw(":error-type"), this.errorType);
            cb(true, r);
        } else {
            var response = new jsedn.Map();

            if (self.contextFields){
                var contextFields = new jsedn.Map();
                for (var key in self.contextFields) {
                    if (self.contextFields.hasOwnProperty(key)) {
                        contextFields.set(new jsedn.kw(key), self.contextFields[key]);
                    }
                }
                response.set(new jsedn.kw(":context-fields"), contextFields);
            }

            self.datacapture.retrieveData(function(status, result){
                if (status){
                    if (self.oneQuestionPerPage){
                        mergeOpts (self.currentOpts, result);
                        var answers = new jsedn.Map();
                        for (var key in self.currentOpts) {
                            if (self.currentOpts.hasOwnProperty(key)) {
                                answers.set(key, self.currentOpts[key]);
                            }
                        }
                        response.set(new jsedn.kw(":answers"), answers);
                        cb(true, response);
                    } else {
                        response.set(new jsedn.kw(":answers"), result);
                        cb(true, response);
                    }

                } else {
                    cb(false, result);
                }
            });
        }
    };

    FormView.prototype.hasActionBarSupport = function () {
        return true;
    }

})();
